import { TextField, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Handle, useStore, Position } from "reactflow";

const connectionNodeIdSelector = state => state.connectionNodeId;

const InterventionNode = ({
  props,
  onTextChange,
  setSelectedNode,
  deleteParentNode,
  setPreviewState,
  setErrorsArray,
  duplicateNode
}) => {
  const [fieldValue, setFieldValue] = useState("");

  useEffect(() => {
    if (props?.data?.value?.text !== "") {
      setFieldValue(props?.data?.value?.text);
    }
  }, []);
  const handleDeleteParentNode = () => {
    deleteParentNode(props?.id);
  };

  const connectionNodeId = useStore(connectionNodeIdSelector);
  const isConnecting = !!connectionNodeId;

  return (
    <>
      <div className="text-updater-node flow-comn-box" onClick={() => {}}>
        <div className="flow-title-box">
          <aside>Message</aside>
          {/* <Handle type="target" className="flow-trigger flow-trigger-fill" /> */}
          {props?.type !== "Template" ? (
            <>
              <div className="header-btn-row">
                {/* <Tooltip title="Preview">
                  <div
                    onClick={() => {
                      setSelectedNode(props?.id);
                      setPreviewState(true);
                    }}
                    className="preview-eye-button"
                  >
                    <i className="fas fa-eye"></i>
                  </div>
                </Tooltip> */}

                <Tooltip title="Clone">
                  <div
                    onClick={() => {
                      duplicateNode(props?.id);
                    }}
                    className="preview-clone-button"
                  >
                    <i className="fas fa-clone"></i>{" "}
                  </div>
                </Tooltip>
                <Tooltip title="Delete">
                  <div
                    onClick={handleDeleteParentNode}
                    className="preview-clone-button"
                  >
                    <i className="fas fa-trash"></i>{" "}
                  </div>
                </Tooltip>
              </div>
            </>
          ) : null}
        </div>

        <div className="flow-inner-row flow-textarea-row">
          <div className="flow-stop-box">
            <div className="flow-stop-icon">
              <i className="fas fa-user-tie"></i>
            </div>
            <div>
              <div>Request Intervention</div>
              <div>Intervene Users and Talk</div>
            </div>
          </div>
          <div style={{ paddingTop: "10px" }}>
            Enter Message
            <TextField
              fullWidth
              placeholder="Enter your text here"
              value={fieldValue}
              multiline
              onChange={e => {
                setFieldValue(e?.target?.value);
                onTextChange(props?.id, { text: e?.target?.value });
              }}
              size="medium"
            />
          </div>
        </div>
        {isConnecting ? (
          <>
            <Handle
              type="target"
              position={Position.Left}
              className="flow-trigger "
              style={{
                overflow: "hidden",
                width: "100%", // Adjust the width as needed
                height: "100%",
                // height: "110%",
                background: isConnecting ? "transparent" : "transparent",
                border: "none",
                position: "absolute",
                left: "0px", // Adjust position to the left side of the node
                top: "50%",
                zIndex: 9,
                borderRadius: 0,
                cursor: isConnecting ? "default" : "move" // Change cursor based on connection state
              }}
              isConnectable={isConnecting} // Disable connection when already connecting
            />
          </>
        ) : (
          <>
            <Handle
              type="target"
              position={Position.Left}
              className="flow-trigger flow-trigger-fill"
              style={{
                left: "1px",
                right: "auto"
              }}
              isConnectable={isConnecting} // Disable connection when already connecting
            />
          </>
        )}
      </div>
    </>
  );
};

export default InterventionNode;
