import React, { useEffect, useState } from "react";
import { Button, TextField, Tooltip } from "@mui/material";
import { Handle, useStore, Position } from "reactflow";
import subscriptionAction from "redux/subscription/action";
import Enhancer from "../Enhancers/TextButtonsEnhancer";

const connectionNodeIdSelector = state => state.connectionNodeId;

const TestingNode = ({
  props,
  setSidebarState,
  setSelectedNode,
  submitCounter,
  deleteParentNode,
  setIsChildLoading,
  setErrorsArray,
  onTextChange,
  handleBlur,
  setPreviewState,
  duplicateNode,
  setValues,
  errors,
  touched,
  submitCount
}) => {
  const { success, error, fetching } = subscriptionAction;

  const [parentId, setParentId] = useState();
  const [allValues, setAllValues] = useState({
    body: "",
    header: "",
    footer: "",
    title: "",
    buttons: []
  });

  const connectionNodeId = useStore(connectionNodeIdSelector);
  const isConnecting = !!connectionNodeId;

  useEffect(() => {
    if (props?.id) {
      setParentId(extractParentId(props?.id));
    }
    if (props?.data?.value && Object.keys(props.data.value).length !== 0) {
      setAllValues(props?.data?.value);
      setValues(props?.data?.value);
    } else {
      setValues(allValues);
    }
  }, []);

  const Error = props => {
    const field1 = props.field;
    if (errors[field1] || touched[field1] || submitCount > 0) {
      return (
        <aside className="error-message-row">
          <span className={props.class ? props.class : "error-msg"}>
            {errors[field1]}
          </span>
        </aside>
      );
    } else {
      return <span />;
    }
  };

  const ButtonError = ({ index }) => {
    const buttonField = `buttons[${index}].value`;
    if ((errors.buttons && errors.buttons[index]) || submitCount > 0) {
      return (
        <aside className="error-message-row">
          <span className={props.class ? props.class : "error-msg"}>
            {errors.buttons[index].value}
          </span>
        </aside>
      );
    } else {
      return <span />;
    }
  };

  function extractParentId(childId) {
    const parts = childId.split("-");
    if (parts.length > 1) {
      return parts[0];
    } else {
      return childId;
    }
  }

  const generateUniqueId = () => {
    const alphanumericCharacters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let uniqueKey = "";
    for (let i = 0; i < 10; i++) {
      const randomIndex = Math.floor(
        Math.random() * alphanumericCharacters.length
      );
      uniqueKey += alphanumericCharacters.charAt(randomIndex);
    }
    return uniqueKey;
  };

  const addTextField = () => {
    if (allValues.buttons.length < 3) {
      const newButton = {
        id: `textField${allValues.buttons.length + 1}`,
        value: "",
        uniqueId: generateUniqueId()
      };
      setAllValues(prevState => {
        let temp = {
          ...prevState,
          buttons: [...prevState.buttons, newButton]
        };
        setValues(temp);
        return temp;
      });
    }
  };

  const handleChange = (id, value, flag) => {
    let updatedAllValues;
    if (flag == true) {
      let maxLength;

      if (id == "body") {
        maxLength = 1024;
      } else if (id == "header" || id == "footer") {
        maxLength = 60;
      }

      let newVal = value;
      if (newVal?.length > maxLength) {
        error(`Only ${maxLength} characters are allowed in ${id}`);
        newVal = newVal.slice(0, maxLength);
      }

      updatedAllValues = {
        ...allValues,
        [id]: newVal
      };
    } else {
      if (value.length > 20) {
        error("Max 20 characters are allowed");
      }
      const updatedButtons = allValues.buttons.map(button =>
        button.id === id
          ? { ...button, value: value.length > 20 ? value.slice(0, 20) : value }
          : button
      );
      updatedAllValues = {
        ...allValues,
        buttons: updatedButtons
      };
    }
    setValues(updatedAllValues);
    setAllValues(updatedAllValues);
    onTextChange(props?.id, updatedAllValues);
  };

  useEffect(() => {
    if (Object.keys(errors).length !== 0) {
      setErrorsArray(arr => {
        const index = arr.findIndex(x => x?.id === props?.id);
        if (index === -1) {
          return [...arr, { id: props?.id }];
        }
        return arr;
      });
    } else {
      setErrorsArray(arr => {
        const filteredArr = arr.filter(x => x?.id !== props?.id);
        return [...filteredArr];
      });
    }
  }, [errors]);

  const removeTextField = idToRemove => () => {
    const updatedButtons = allValues.buttons.filter(
      button => button.id !== idToRemove
    );
    const updatedAllValues = {
      ...allValues,
      buttons: updatedButtons
    };
    setValues(updatedAllValues);
    setAllValues(updatedAllValues);
    onTextChange(props?.id, updatedAllValues);
  };

  const handleDeleteParentNode = () => {
    deleteParentNode(props?.id);
  };

  return (
    <div className="text-updater-node flow-comn-box" onClick={() => {}}>
      <div className="flow-title-box">
        <aside>Message</aside>
        {props?.type !== "Template" ? (
          <div className="header-btn-row">
            <Tooltip title="Preview">
              <div
                onClick={() => {
                  setSelectedNode(props?.id);
                  setPreviewState(true);
                }}
                className="preview-eye-button"
              >
                <i className="fas fa-eye"></i>
              </div>
            </Tooltip>
            <Tooltip title="Clone">
              <div
                onClick={() => {
                  duplicateNode(props?.id);
                }}
                className="preview-clone-button"
              >
                <i className="fas fa-clone"></i>
              </div>
            </Tooltip>
            <Tooltip title="Delete">
              <div
                onClick={handleDeleteParentNode}
                className="preview-clone-button"
              >
                <i className="fas fa-trash"></i>
              </div>
            </Tooltip>
          </div>
        ) : null}
      </div>
      <div className="flow-inner-row flow-textarea-row">
        {["header", "body", "footer"].map(fieldId => (
          <aside key={fieldId}>
            <TextField
              className="textfield-padding-small"
              size="large"
              multiline
              id={fieldId}
              onBlur={handleBlur}
              placeholder={
                fieldId === "body" ? `${fieldId}` : `${fieldId} (optional)`
              }
              minRows={fieldId === "body" ? 3 : 1}
              value={allValues[fieldId] ? allValues[fieldId] : ""}
              onChange={e => handleChange(fieldId, e.target.value, true)}
              // helperText={
              //   allValues[fieldId]?.length !== 0
              //     ? `${allValues[fieldId]?.length}/${
              //         fieldId == "body"
              //           ? 1024
              //           : fieldId == "header" || fieldId == "footer"
              //           ? 60
              //           : null
              //       }`
              //     : null
              // }
            />
            <Error field={fieldId} />
            <div className="counter-class">
              {allValues[fieldId] &&
              allValues[fieldId] !== undefined &&
              allValues[fieldId]?.length !== 0
                ? `${allValues[fieldId]?.length}/${
                    fieldId == "body"
                      ? 1024
                      : fieldId == "header" || fieldId == "footer"
                      ? 60
                      : null
                  }`
                : null}
            </div>
          </aside>
        ))}
        {allValues.buttons.map((button, index) => (
          <div
            key={button.id}
            style={{ position: "relative" }}
            className="text-btn-add-row"
          >
            <TextField
              label={`Button Title ${index + 1}`}
              value={button.value}
              className="w-100 required-field textfield-padding-small"
              multiline
              onChange={e => handleChange(button.id, e.target.value)}
            />
            {/* <Handle
              type="source"
              position={Position.Right}
              className="flow-trigger"
            /> */}
            <div className="counter-class">
              {button.value?.length !== 0 ? `${button.value?.length}/20` : null}
            </div>
            <div>
              <Handle
                id={`${props?.id}_${button.id}`}
                type="source"
                className="flow-trigger"
              ></Handle>
            </div>
            <p
              className="flow-comn-close-btn"
              onClick={removeTextField(button.id)}
              style={{ cursor: "pointer" }}
            >
              <i className="fas fa-times-circle"></i>
            </p>
            <ButtonError index={index} />
          </div>
        ))}
        {allValues.buttons.length < 3 && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Button
              variant="contained"
              onClick={addTextField}
              className="flow-comn-btn"
            >
              <i className="fas fa-plus mr-2"></i> Add Button
            </Button>
          </div>
        )}
      </div>

      {isConnecting ? (
        <>
          <Handle
            type="target"
            position={Position.Left}
            className="flow-trigger "
            style={{
              overflow: "hidden",
              width: "100%", // Adjust the width as needed
              height: "100%",
              // height: "110%",
              background: isConnecting ? "transparent" : "transparent",
              border: "none",
              position: "absolute",
              left: "0px", // Adjust position to the left side of the node
              top: "50%",
              zIndex: 9,
              borderRadius: 0,
              cursor: isConnecting ? "default" : "move" // Change cursor based on connection state
            }}
            isConnectable={isConnecting} // Disable connection when already connecting
          />
        </>
      ) : (
        <>
          <Handle
            type="target"
            position={Position.Left}
            className="flow-trigger flow-trigger-fill"
            style={{
              left: "1px",
              right: "auto"
            }}
            isConnectable={isConnecting} // Disable connection when already connecting
          />
        </>
      )}
    </div>
  );
};

export default Enhancer(TestingNode);
