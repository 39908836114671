import { TextField, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Handle, useStore, Position } from "reactflow";

import subscriptionAction from "redux/subscription/action";
import Enhancer from "../Enhancers/TextButtonsEnhancer";

const connectionNodeIdSelector = state => state.connectionNodeId;

const AINode = ({
  props,
  onTextChange,
  setSelectedNode,
  deleteParentNode,
  setPreviewState,
  setIsChildLoading,
  handleBlur,
  touched,
  errors,
  values,
  setErrorsArray,
  duplicateNode,
  setValues
}) => {
  const [fieldValue, setFieldValue] = useState("");

  useEffect(() => {
    if (props?.data?.value?.text !== "") {
      setFieldValue(props?.data?.value?.text);
    }
  }, []);
  const handleDeleteParentNode = () => {
    deleteParentNode(props?.id);
  };

  const connectionNodeId = useStore(connectionNodeIdSelector);
  const isConnecting = !!connectionNodeId;

  const { success, error, fetching } = subscriptionAction;

  const [allValues, setAllValues] = useState({
    header: "",
    body: "",
    footer: ""
  });

  useEffect(() => {
    if (Object.keys(errors)?.length !== 0) {
      setErrorsArray(arr => {
        const index = arr.findIndex(x => x?.id === props?.idb);
        if (index === -1) {
          return [...arr, { id: props?.id }];
        }
        return arr; // If the error for this component already exists in the array, no need to update
      });
    } else {
      setErrorsArray(arr => {
        const filteredArr = arr.filter(x => x?.id !== props?.id);
        return [...filteredArr]; // Return a new array without the error for the current component
      });
    }
    return () => {};
  }, [allValues, errors]);

  useEffect(() => {
    if (props?.data?.value) {
      setAllValues(props?.data?.value);
      onTextChange(props?.id, props?.data?.value);
      setValues(props?.data?.value);
    }
  }, []);

  useEffect(() => {
    console.log(allValues, "All values inside useEffect");
    console.log(errors, "errors", values);
    setValues(allValues);
  }, [allValues, errors]);

  const handleChange = (field, value) => {
    let maxLength = 0;

    if (field === "body") {
      maxLength = 1024;
    } else if (field === "header" || field === "footer") {
      maxLength = 60;
    }

    // Trim value if it exceeds maxLength
    if (value && value.length > maxLength) {
      value = value.slice(0, maxLength);
      error(`Maximum ${maxLength} characters are allowed in ${field}`);
    }

    const temp = {
      ...allValues,
      [field]: value
    };
    onTextChange(props?.id, temp);
    setAllValues(temp);
    setValues(temp);
  };

  const Error = props => {
    let field = props.field;

    // Ensure errors is defined and field is valid
    if (!errors || !field) {
      console.log("errors or field is undefined");
      return null;
    }

    if (errors[field] || touched[field]) {
      return (
        <aside style={{ marginTop: "5px" }} className="error-message-row">
          <span className="error-msg">{errors[field]}</span>
        </aside>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      <div className="text-updater-node flow-comn-box" onClick={() => {}}>
        <div className="flow-title-box">
          <aside>Message</aside>
          <div className="header-btn-row">
            <Tooltip title="Clone">
              <div
                onClick={() => {
                  duplicateNode(props?.id);
                }}
                className="preview-clone-button"
              >
                <i className="fas fa-clone"></i>{" "}
              </div>
            </Tooltip>
            <Tooltip title="Delete">
              <div
                onClick={handleDeleteParentNode}
                className="preview-clone-button"
              >
                <i className="fas fa-trash"></i>{" "}
              </div>
            </Tooltip>
          </div>
        </div>

        <div className="flow-inner-row flow-textarea-row">
          <div className="flow-stop-box">
            <div className="flow-stop-icon">
              <i className="fas fa-comment-dots"></i>{" "}
            </div>
            <div>
              <div>AI Conversaion</div>
            </div>
          </div>
        </div>
        <div style={{ height: "10px" }}></div>

        <div
          style={{ position: "relative" }}
          className="flow-inner-row flow-textarea-row"
        >
          <div style={{ position: "relative" }}>
            <TextField
              size="large"
              className="w-100 textfield-padding-small"
              multiline
              minRows={3}
              placeholder="Enter Text Here"
              value={allValues["body"] || ""}
              onChange={e => handleChange("body", e.target.value)}
            />
            <Error field={"body"} />

            <div className="counter-class">
              {allValues["body"]?.length !== 0
                ? `${allValues["body"]?.length}/${1024}`
                : null}
            </div>
          </div>
        </div>

        {isConnecting ? (
          <>
            <Handle
              type="target"
              position={Position.Left}
              className="flow-trigger "
              style={{
                overflow: "hidden",
                width: "100%", // Adjust the width as needed
                height: "100%",
                // height: "110%",
                background: isConnecting ? "transparent" : "transparent",
                border: "none",
                position: "absolute",
                left: "0px", // Adjust position to the left side of the node
                top: "50%",
                zIndex: 9,
                borderRadius: 0,
                cursor: isConnecting ? "default" : "move" // Change cursor based on connection state
              }}
              isConnectable={isConnecting} // Disable connection when already connecting
            />
          </>
        ) : (
          <>
            <Handle
              type="target"
              position={Position.Left}
              className="flow-trigger flow-trigger-fill"
              style={{
                left: "1px",
                right: "auto"
              }}
              isConnectable={isConnecting} // Disable connection when already connecting
            />
          </>
        )}
      </div>
    </>
  );
};

export default Enhancer(AINode);
