import React, { useEffect, useState } from "react";
import { TextField, Tooltip } from "@mui/material";
import { Handle, useStore, Position } from "reactflow";
import subscriptionAction from "redux/subscription/action";
const connectionNodeIdSelector = state => state.connectionNodeId;
const TestingTestNode = ({
  props,
  onTextChange,
  setSelectedNode,
  deleteParentNode,
  setPreviewState,
  setErrorsArray,
  duplicateNode
}) => {
  const { success, error, fetching } = subscriptionAction;

  const [fieldValue, setFieldValue] = useState("");
  const [parentId, setParentId] = useState("");
  const [isValid, setIsValid] = useState(false);

  const handleChange = event => {
    let value = event.target.value;
    if (value?.length > 1024) {
      error("Maximum 1024 characters are allowed in body");
      value = value.slice(0, 1024);
    }
    const dataToSend = {
      text: value
    };
    onTextChange(props?.id, dataToSend);
    setFieldValue(event.target.value);
  };
  const handleDeleteParentNode = () => {
    deleteParentNode(props?.id);
  };

  useEffect(() => {
    if (fieldValue == "") {
      setErrorsArray(arr => {
        const index = arr.findIndex(x => x?.id === props?.id);
        if (index === -1) {
          return [...arr, { id: props?.id }];
        }
        return arr; // If the error for this component already exists in the array, no need to update
      });
    } else {
      setErrorsArray(arr => {
        const filteredArr = arr.filter(x => x?.id !== props?.id);
        return [...filteredArr]; // Return a new array without the error for the current component
      });
    }
  }, [fieldValue]);
  const Error = () => {
    if (isValid == false) {
      return (
        <>
          {/* <p className={`${props.class ? props.class : "error-msg w-100"}`}> */}
          {/* </p> */}
          <aside className="error-message-row">
            <span className={"error-msg"}>Text Field can not be empty </span>
          </aside>
        </>
      );
    } else {
      return <span />;
    }
  };

  const connectionNodeId = useStore(connectionNodeIdSelector);
  const isConnecting = !!connectionNodeId;
  useEffect(() => {
    if (fieldValue == "") {
      setIsValid(false);
    } else {
      setIsValid(true);
    }
  }, [fieldValue]);
  useEffect(() => {
    if (props?.data?.value?.text) {
      setFieldValue(props?.data?.value?.text);
    }
    if (typeof props?.data?.value == "string") {
      console.log("from checking datatype");
      const dataToSend = {
        text: props?.data?.value
      };
      setFieldValue(props?.data?.value);
      onTextChange(props?.id, dataToSend);
    }
  }, []);

  return (
    <>
      <div className="text-updater-node flow-comn-box" onClick={() => {}}>
        <div className="flow-title-box">
          <aside>Message</aside>
          {/* <Handle type="target" className="flow-trigger flow-trigger-fill" /> */}
          {props?.type !== "Template" ? (
            <>
              <div className="header-btn-row">
                <Tooltip title="Preview">
                  <div
                    onClick={() => {
                      setSelectedNode(props?.id);
                      setPreviewState(true);
                    }}
                    className="preview-eye-button"
                  >
                    <i className="fas fa-eye"></i>
                  </div>
                </Tooltip>

                <Tooltip title="Clone">
                  <div
                    onClick={() => {
                      duplicateNode(props?.id);
                    }}
                    className="preview-clone-button"
                  >
                    <i className="fas fa-clone"></i>{" "}
                  </div>
                </Tooltip>
                <Tooltip title="Delete">
                  <div
                    onClick={handleDeleteParentNode}
                    className="preview-clone-button"
                  >
                    <i className="fas fa-trash"></i>{" "}
                  </div>
                </Tooltip>
              </div>
            </>
          ) : null}
        </div>

        <div className="flow-inner-row flow-textarea-row">
          <aside>
            <TextField
              size="large"
              className="textfield-padding-small"
              multiline
              minRows={4}
              placeholder="Enter value here"
              value={fieldValue}
              onChange={handleChange}
            />
            <div className="counter-class">
              {fieldValue.length !== 0 ? `${fieldValue.length}/1024` : null}
            </div>
            <Error />
          </aside>
        </div>
        {isConnecting ? (
          <>
            <Handle
              type="target"
              position={Position.Left}
              className="flow-trigger "
              style={{
                overflow: "hidden",
                width: "100%", // Adjust the width as needed
                height: "100%",
                // height: "110%",
                background: isConnecting ? "transparent" : "transparent",
                border: "none",
                position: "absolute",
                left: "0px", // Adjust position to the left side of the node
                top: "50%",
                zIndex: 9,
                borderRadius: 0,
                cursor: isConnecting ? "default" : "move" // Change cursor based on connection state
              }}
              isConnectable={isConnecting} // Disable connection when already connecting
            />
          </>
        ) : (
          <>
            <Handle
              type="target"
              position={Position.Left}
              className="flow-trigger flow-trigger-fill"
              style={{
                left: "1px",
                right: "auto"
              }}
              isConnectable={isConnecting} // Disable connection when already connecting
            />
          </>
        )}
      </div>
    </>
  );
};

export default TestingTestNode;
