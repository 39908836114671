import React, { useEffect, useState } from "react";
import { Grid, TextField, Tooltip } from "@mui/material";
import { Handle } from "reactflow";
import subscriptionAction from "redux/subscription/action";
import Enhancer from "../Enhancers/ButtonUrlEnhancer";

const TestingButtonUrlNode = ({
  props,
  onTextChange,
  deleteParentNode,
  setPreviewState,
  duplicateNode,
  setSelectedNode,
  setValues,
  errors,
  setErrorsArray,
  touched,
  values,
  submitCount,
  handleBlur
}) => {
  const { success, error, fetching } = subscriptionAction;

  const [parentId, setParentId] = useState();

  const [allValues, setAllValues] = useState({
    body: "",
    header: "",
    footer: "",
    buttonTitle: "",
    buttonUrl: ""
  });

  useEffect(() => {
    if (Object.keys(errors).length !== 0) {
      setErrorsArray(arr => {
        const index = arr.findIndex(x => x?.id === props?.id);
        if (index === -1) {
          return [...arr, { id: props?.id }];
        }
        return arr; // If the error for this component already exists in the array, no need to update
      });
    } else {
      setErrorsArray(arr => {
        const filteredArr = arr.filter(x => x?.id !== props?.id);
        return [...filteredArr]; // Return a new array without the error for the current component
      });
    }
  }, [errors]);

  useEffect(() => {
    if (props?.id) {
      setParentId(extractParentId(props?.id));
    }
    if (props?.data?.value && Object.keys(props.data.value).length !== 0) {
      setAllValues(props?.data?.value);
      setValues(props?.data?.value);
    } else {
      setValues(allValues);
    }
  }, []);

  function extractParentId(childId) {
    const parts = childId.split("-");
    if (parts.length > 1) {
      return parts[0];
    } else {
      return childId;
    }
  }

  const Error = props => {
    const field1 = props.field;
    if (errors[field1] || touched[field1] || submitCount > 0) {
      return (
        <>
          {/* <p className={`${props.class ? props.class : "error-msg w-100"}`}> */}
          {/* </p> */}
          <aside className="error-message-row">
            <span className={props.class ? props.class : "error-msg"}>
              {errors[field1]}
            </span>
          </aside>
        </>
      );
    } else {
      return <span />;
    }
  };

  const handleDeleteParentNode = () => {
    deleteParentNode(parentId);
  };

  const handleChange = (id, value) => {
    // const updatedFields = textFields.map((field) =>
    //   field.id === id ? { ...field, value } : field
    // );

    if (id == "body" && value?.length > 1024) {
      const temp = {
        ...allValues,
        [id]: value.slice(0, 1024)
      };
      onTextChange(props?.id, temp);
      setValues(temp);
      setAllValues(temp);

      error("Max 1024 characters are allowed");
    } else if (id == "buttonTitle" && value?.length > 20) {
      const temp = {
        ...allValues,
        [id]: value.slice(0, 20)
      };
      onTextChange(props?.id, temp);
      setValues(temp);
      setAllValues(temp);

      error("Max 20 characters are allowed");
    } else {
      const temp = {
        ...allValues,
        [id]: value
      };
      onTextChange(props?.id, temp);
      setValues(temp);
      setAllValues(temp);
    }
  };

  return (
    <>
      <div className="text-updater-node flow-comn-box">
        <div className="flow-title-box">
          <aside>Message</aside>
          <Handle type="target" className="flow-trigger flow-trigger-fill" />
          {props?.type !== "Template" ? (
            <>
              <div className="header-btn-row">
                <Tooltip title="Preview">
                  <div
                    onClick={() => {
                      setSelectedNode(props?.id);
                      setPreviewState(true);
                    }}
                    className="preview-eye-button"
                  >
                    <i className="fas fa-eye"></i>
                  </div>
                </Tooltip>

                <Tooltip title="Clone">
                  <div
                    onClick={() => {
                      duplicateNode(props?.id);
                    }}
                    className="preview-clone-button"
                  >
                    <i className="fas fa-clone"></i>{" "}
                  </div>
                </Tooltip>
                <Tooltip title="Delete">
                  <div
                    onClick={handleDeleteParentNode}
                    className="preview-clone-button"
                  >
                    <i className="fas fa-trash"></i>{" "}
                  </div>
                </Tooltip>
              </div>
            </>
          ) : null}
        </div>

        <div className="flow-inner-row flow-textarea-row">
          {["header", "body", "footer", "buttonTitle", "buttonUrl"].map(
            field => (
              <>
                <aside>
                  <TextField
                    size="large"
                    multiline
                    id={field}
                    onBlur={handleBlur}
                    className={field == "buttonTitle" ? "mt-2" : null}
                    placeholder={
                      field == "body" ||
                      field == "buttonUrl" ||
                      field == "buttonTitle"
                        ? `${field}`
                        : `${field} (optional)`
                    }
                    minRows={
                      field == "body"
                        ? 4
                        : field == "buttonUrl" || field == "buttonTitle"
                        ? 2
                        : 1
                    }
                    key={field.id}
                    value={allValues[field]}
                    onChange={e => handleChange(field, e.target.value)}
                  />
                  <Error field={field} />
                </aside>
              </>
            )
          )}

          <div>{/* <pre>{JSON.stringify(allValues, null, 2)}</pre> */}</div>
        </div>
      </div>
    </>
  );
};

export default Enhancer(TestingButtonUrlNode);
