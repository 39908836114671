import React, { useEffect, useRef, useState } from "react";
import subscriptionAction from "redux/subscription/action";

import { Button, Grid, Input, TextField, Tooltip } from "@mui/material";
import { Handle, Position, useStore } from "reactflow";
import { getImageUrl } from "services/flowServices";
import defaultImageForPdf from "../../../../assets/images/pdf_placeholder_image.png";
import defaultImageForVideo from "../../../../assets/images/defaultVideoPic.png";
import Enhancer from "../Enhancers/MediaButtonEhancer";

const connectionNodeIdSelector = state => state.connectionNodeId;

const TestingMediaButtonNode = (
  //   {
  //   props,
  //   setSidebarState,
  //   setSelectedNode,
  //   deleteParentNode,
  //   setPreviewState,
  //   duplicateNode,
  //   setIsChildLoading,
  //   onTextChange,
  //   setErrorsArray,
  //   companyId,
  //   setValues,
  //   nodeIdsRef,
  //   errors,
  //   touched,
  //   values,
  //   submitCount,
  //   handleBlur,
  // }
  {
    props,
    deleteParentNode,
    setIsChildLoading,
    setPreviewState,
    companyId,
    setSelectedNode,
    onTextChange,
    duplicateNode,
    touched,
    onImageChange,
    submitCount,
    setErrorsArray,
    setValues,
    values,
    handleBlur,
    errors
  }
) => {
  const { success, error, fetching } = subscriptionAction;

  const connectionNodeId = useStore(connectionNodeIdSelector);
  const isConnecting = !!connectionNodeId;

  const [parentId, setParentId] = useState();
  useEffect(() => {
    if (Object.keys(errors).length !== 0) {
      setErrorsArray(arr => {
        const index = arr.findIndex(x => x?.id === props?.idb);
        if (index === -1) {
          return [...arr, { id: props?.id }];
        }
        return arr; // If the error for this component already exists in the array, no need to update
      });
    } else {
      setErrorsArray(arr => {
        const filteredArr = arr.filter(x => x?.id !== props?.id);
        return [...filteredArr]; // Return a new array without the error for the current component
      });
    }
    return () => {};
  }, [errors]);

  const [additionalFields, setAdditionalFields] = useState([]);
  const [allValues, setAllValues] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isVideo, setIsVideo] = useState(false);
  const [isPdf, setIsPdf] = useState(false);
  const [preview, setPreview] = useState("");
  const [fileName, setFileName] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [imageUrl, setImageUrl] = useState("");

  const [valuesObj, setValuesObj] = useState({
    imageLink: "",
    fileName: "",
    isPdf: false,
    isVideo: false,
    isImage: true,
    buttons: [],
    body: ""
  });

  useEffect(() => {
    if (props?.id) {
      setParentId(props?.id);
    }
    if (props?.data?.value && Object.keys(props.data.value).length !== 0) {
      setValuesObj(props?.data?.value);
      setFileName(props?.data?.value?.fileName);
      setPreview(props?.data?.value?.imageLink);
      setValues(props?.data?.value);
      setIsPdf(props?.data?.value?.isPdf);
      setIsVideo(props?.data?.value?.isVideo);
    } else {
      let temp = {
        imageLink: "",
        body: "",
        buttons: []
      };
      setValues(temp);
    }
  }, []);

  const generateUniqueId = () => {
    const alphanumericCharacters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let uniqueKey = "";

    for (let i = 0; i < 10; i++) {
      const randomIndex = Math.floor(
        Math.random() * alphanumericCharacters.length
      );
      uniqueKey += alphanumericCharacters.charAt(randomIndex);
    }

    return uniqueKey;
  };
  const addTextField = () => {
    if (valuesObj.buttons.length < 3) {
      const newButton = {
        id: `button${valuesObj.buttons.length + 1}`,
        value: "",
        uniqueId: generateUniqueId()
      };
      const buttons = valuesObj.buttons.map((d, i) => ({
        id: `button ${i}`,
        value: d.value
      }));
      setValuesObj(prevState => {
        const updatedValuesObj = {
          ...prevState,
          buttons: [...prevState.buttons, newButton]
        };

        setValues(updatedValuesObj);
        // Call the other function with the updated valuesObj
        onTextChange(props?.id, updatedValuesObj);
        return updatedValuesObj;
      });
    }
  };

  const Error = props => {
    const field1 = props.field;
    if (errors[field1] || touched[field1] || submitCount > 0) {
      return (
        <>
          {/* <p className={`${props.class ? props.class : "error-msg w-100"}`}> */}
          {/* </p> */}
          <aside className="error-message-row">
            <span className={props.class ? props.class : "error-msg"}>
              {errors[field1]}
            </span>
          </aside>
        </>
      );
    } else {
      return <span />;
    }
  };

  // Modified Error component to handle errors for buttons
  const ButtonError = ({ index }) => {
    const buttonField = `buttons[${index}].value`;

    if (
      (errors.buttons && errors.buttons[index]) || // Check if the button field is touched
      submitCount > 0
    ) {
      return (
        <aside className="error-message-row">
          <span className={props.class ? props.class : "error-msg"}>
            {errors.buttons[index].value}
          </span>
        </aside>
      );
    } else {
      return <span />;
    }
  };

  useEffect(() => {
    if (Object.keys(errors).length !== 0) {
      setIsChildLoading(true);
    } else {
      setIsChildLoading(false);
    }
  }, [errors]);

  const removeTextField = index => () => {
    const updatedButtons = valuesObj.buttons.filter((_, i) => i !== index);
    const buttons = updatedButtons.map((d, i) => ({
      id: `button ${i}`,
      value: d.value
    }));

    setValuesObj(prevState => {
      const updatedValuesObj = {
        ...prevState,
        buttons
      };
      // Call the other function with the updated valuesObj
      setValues(updatedValuesObj);
      onTextChange(props?.id, updatedValuesObj);
      return updatedValuesObj;
    });
  };

  const handleDeleteParentNode = () => {
    deleteParentNode(props?.id);
  };

  const handleTextChange = (id, value, flag) => {
    if (flag == "buttons") {
      const index = valuesObj.buttons.findIndex(x => x.id == id);
      const buttonData = valuesObj.buttons;

      if (value.length > 20) {
        error("Max 20 characters are allowed");
      }
      buttonData[index] = {
        id: buttonData[index].id,
        value: value.length > 20 ? value.slice(0, 20) : value,
        uniqueId: buttonData[index]?.uniqueId
      };
      let temp;
      temp = {
        ...valuesObj,
        buttons: buttonData
      };

      onTextChange(props?.id, temp);
      setValues(temp);
      setValuesObj(temp);
    } else if (flag == "body") {
      // let temp = valuesObj
      // temp.body = value
      if (value?.length > 1024) {
        error("Max 1024 characters are allowed in body");
        value = value.slice(0, 1024);
      }
      let temp = {
        ...valuesObj,
        body: value
      };

      onTextChange(props?.id, temp);
      setValues(temp);
      setValuesObj(temp);
    } else if (flag == "image") {
      // debugger;
      let temp2 = {
        ...valuesObj,
        ...value
      };
      onTextChange(props?.id, temp2);
      setValues(temp2);
      setValuesObj(temp2);
    }
  };

  const handleFileUpload = event => {
    try {
      setIsChildLoading(true);
      setIsLoading(true);
      const file = event.target.files[0];

      if (!file) {
        // No file selected
        return;
      }

      // Validate file size
      const maxSize = 15 * 1024 * 1024; // 2MB
      if (file.size > maxSize) {
        error("File size exceeds 10 MB limit. Please choose a smaller file.");
        return;
      }
      setFileName(prevName => file.name);

      if (file.type.includes("image")) {
        // Handle image file
        handleImage(file, file.name);
      } else if (file.type === "application/pdf") {
        // Handle PDF file
        handlePDF(file, file.name);
      } else if (file.type.includes("video")) {
        handleVideo(file, file.name);
      } else {
        error(
          "Invalid file type. Please select an image (png, jpg, jpeg, svg) or a PDF file."
        );
      }
    } catch (error) {
      setIsChildLoading(false);
    }
  };

  const handleImage = (file, filename) => {
    // Validate file type
    const allowedTypes = [
      "image/png",
      "image/jpeg",
      "image/jpg",
      "image/svg+xml"
    ];
    if (!allowedTypes.includes(file.type)) {
      error(
        "Invalid file type. Please select a .png, .jpg, .jpeg, .svg or .pdf file."
      );
      return;
    }

    // Read the file and convert to base64
    const reader = new FileReader();
    reader.onloadend = async () => {
      const base64String = reader.result;
      setSelectedFile(file);
      setPreview(URL.createObjectURL(file));
      setIsPdf(false);
      setIsVideo(false);
      // You can now send the base64String to your backend
      const formData = new FormData();

      formData.append("file", file);
      formData.append("company_id", companyId);

      const imageUrlRes = await getImageUrl(formData);

      if (imageUrlRes?.status == "success") {
        // return;
        setImageUrl(imageUrlRes?.url);

        const dataToSend = {
          imageLink: imageUrlRes?.url,
          fileName: filename,
          isImage: true,
          isPdf: false,
          isVideo: false
        };
        handleTextChange(props?.id, dataToSend, "image");
        setIsLoading(false);
        setIsChildLoading(false);
      } else {
        error("Upload Failed");
        setIsLoading(false);
        setFileName("");
        setPreview(null);
      }
    };

    reader.readAsDataURL(file);
  };

  const handlePDF = (file, filename) => {
    // Read the file and convert to base64
    const reader = new FileReader();
    reader.onloadend = async () => {
      const base64String = reader.result;
      setSelectedFile(file);
      // Set a default preview image for PDF files or display a PDF icon
      setPreview(defaultImageForPdf);
      // You can now send the base64String to your backend
      setIsPdf(true);
      setIsVideo(false);

      const formData = new FormData();

      formData.append("file", file);
      formData.append("company_id", companyId);

      const imageUrlRes = await getImageUrl(formData);

      if (imageUrlRes?.status == "success") {
        setImageUrl(imageUrlRes?.url);
        const dataToSend = {
          imageLink: imageUrlRes?.url,
          fileName: filename,
          isImage: false,
          isPdf: true,
          isVideo: false
        };
        handleTextChange(props?.id, dataToSend, "image");
        setIsLoading(false);
        setIsChildLoading(false);
      }
    };
    reader.readAsDataURL(file);
  };

  const handleVideo = (file, filename) => {
    const allowedVideoTypes = [
      "video/mp4",
      "video/mpeg",
      "video/webm",
      "video/quicktime",
      "video/x-msvideo"
    ];

    if (!allowedVideoTypes.includes(file.type)) {
      error(
        "Invalid file type. Please select a .png, .jpg, .jpeg, .svg or .pdf file."
      );
      return;
    }

    const reader = new FileReader();
    reader.onloadend = async () => {
      const base64String = reader.result;
      setSelectedFile(file);
      // Set a default preview image for PDF files or display a PDF icon
      setPreview(defaultImageForVideo);
      // You can now send the base64String to your backend
      setIsPdf(false);
      setIsVideo(true);

      const formData = new FormData();

      formData.append("file", file);
      formData.append("company_id", companyId);

      const imageUrlRes = await getImageUrl(formData);

      if (imageUrlRes?.status == "success") {
        setImageUrl(imageUrlRes?.url);
        const dataToSend = {
          imageLink: imageUrlRes?.url,
          fileName: filename,
          isImage: false,
          isPdf: false,
          isVideo: true
        };
        handleTextChange(props?.id, dataToSend, "image");
        setIsLoading(false);
        setIsChildLoading(false);
      }
    };
    reader.readAsDataURL(file);
  };

  return (
    <>
      <div className="text-updater-node flow-comn-box" onClick={() => {}}>
        <div className="flow-title-box">
          <aside>Message</aside>
          {/* <Handle type="target" className="flow-trigger flow-trigger-fill" /> */}
          {props?.type !== "Template" ? (
            <>
              <div className="header-btn-row">
                <Tooltip title="Preview">
                  <div
                    onClick={() => {
                      setSelectedNode(props?.id);
                      setPreviewState(true);
                    }}
                    className="preview-eye-button"
                  >
                    <i className="fas fa-eye"></i>
                  </div>
                </Tooltip>

                <Tooltip title="Clone">
                  <div
                    onClick={() => {
                      duplicateNode(props?.id);
                    }}
                    className="preview-clone-button"
                  >
                    <i className="fas fa-clone"></i>{" "}
                  </div>
                </Tooltip>
                <Tooltip title="Delete">
                  <div
                    onClick={handleDeleteParentNode}
                    className="preview-clone-button"
                  >
                    <i className="fas fa-trash"></i>{" "}
                  </div>
                </Tooltip>
              </div>
            </>
          ) : null}
        </div>

        <div className="flow-inner-row">
          <div className="flow-inner-box">
            {fileName !== "" ? (
              <>
                <div className="flow-file-input" style={{ maxHeight: "200px" }}>
                  <i className="fas fa-image"></i>
                  <label
                    htmlFor={`imageLink_${props?.id}`}
                    className="file-upload-name-label"
                  >
                    {fileName}
                  </label>
                  <Input
                    key={props?.id}
                    type="file"
                    id={`imageLink_${props?.id}`}
                    name="imageLink"
                    className="file-upload-name-input"
                    onChange={e => {
                      // e.preventDefault();

                      handleFileUpload(e);
                    }}
                    // onBlur={handleBlur}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="flow-file-input">
                  <i className="fas fa-image"></i>
                  <Input
                    key={props?.id}
                    id={`imageLink_${props?.id}`}
                    name="imageLink"
                    type="file"
                    // onBlur={handleBlur}
                    onChange={e => {
                      // e.preventDefault();

                      handleFileUpload(e);
                    }}
                  />
                  <Error field="imageLink" />
                </div>
              </>
            )}
            {isLoading ? (
              <div className="flow-image-preview-box py-4">
                <div className="myuihub-loader3"></div>
              </div>
            ) : preview ? (
              <div className="flow-image-preview-box">
                <img
                  src={
                    isPdf
                      ? defaultImageForPdf
                      : isVideo
                      ? defaultImageForVideo
                      : preview
                  }
                />
              </div>
            ) : (
              <></>
            )}

            <span
              style={{
                display: "block",
                backgroundColor: "#E2E2E2",
                textTransform: "lowercase",
                width: "100%",
                margin: "10px 0px 0px",
                padding: "5px 10px",
                borderRadius: "3px",
                paddingTop: "10px"
              }}
            >
              {" "}
              You can upload Image,Video, or Pdf here{" "}
            </span>
          </div>

          <aside className="position-relative">
            <TextField
              className="textfield-padding-small"
              size="large"
              // id="body"
              multiline
              onBlur={handleBlur}
              value={valuesObj?.body}
              placeholder="Body "
              minRows={3}
              style={{
                backgroundColor: "white",
                width: "100%",
                borderRadius: "4px"
              }}
              onChange={e => {
                handleTextChange("body", e.target.value, "body");
              }}
            />
            <div className="counter-class">
              {valuesObj.body?.length !== 0
                ? `${valuesObj?.body?.length}/1024`
                : null}
            </div>
            <Error field="body" />
          </aside>
          {valuesObj?.buttons?.length > 0 &&
            valuesObj?.buttons?.map((field, index) => (
              <div className="flow-textarea-row">
                <div
                  className="text-btn-add-row"
                  style={{ position: "relative " }}
                >
                  <TextField
                    label={`Button Title ${index + 1}`}
                    value={field.value}
                    multiline
                    id="buttons"
                    onBlur={handleBlur}
                    className="w-100 required-field textfield-padding"
                    onChange={e =>
                      handleTextChange(field.id, e.target.value, "buttons")
                    }
                  />
                  <p>
                    <Handle
                      id={`${props?.id}_${field.id}`}
                      type="source"
                      className="flow-trigger"
                    ></Handle>
                  </p>
                  <ButtonError index={index} />
                  <p
                    className="flow-comn-close-btn"
                    onClick={removeTextField(index)}
                    style={{ cursor: "pointer" }}
                  >
                    <i className="fas fa-times-circle"></i>
                  </p>
                  <div className="counter-class">
                    {field.value?.length !== 0
                      ? `${field.value?.length}/20`
                      : null}
                  </div>
                </div>
                {/* <div
                  className="flow-comn-close-btn"
                  onClick={() => {
                    handleDeleteParentNode();
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <i className="fas fa-times-circle"></i>
                </div> */}
              </div>
            ))}

          {valuesObj.buttons?.length < 3 && (
            <Button
              className="flow-comn-btn"
              variant="contained"
              onClick={addTextField}
            >
              <i className="fas fa-plus mr-2"></i> Add Button
            </Button>
          )}
          {/* Displaying all values of text fields */}
          {/* <pre>{JSON.stringify(valuesObj, null, 2)}</pre> */}
        </div>

        {isConnecting ? (
          <>
            <Handle
              type="target"
              position={Position.Left}
              className="flow-trigger "
              style={{
                overflow: "hidden",
                width: "100%", // Adjust the width as needed
                height: "100%",
                // height: "110%",
                background: isConnecting ? "transparent" : "transparent",
                border: "none",
                position: "absolute",
                left: "0px", // Adjust position to the left side of the node
                top: "50%",
                zIndex: 9,
                borderRadius: 0,
                cursor: isConnecting ? "default" : "move" // Change cursor based on connection state
              }}
              isConnectable={isConnecting} // Disable connection when already connecting
            />
          </>
        ) : (
          <>
            <Handle
              type="target"
              position={Position.Left}
              className="flow-trigger flow-trigger-fill"
              style={{
                left: "1px",
                right: "auto"
              }}
              isConnectable={isConnecting} // Disable connection when already connecting
            />
          </>
        )}
      </div>
    </>
  );
};

export default Enhancer(TestingMediaButtonNode);
