import {
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import subscriptionAction from "redux/subscription/action";
import Enhancer from "../Enhancers/AttributeEnhancer";
import { getAttributDropDownData, getImageUrl } from "services/flowServices";
import { Handle, useStore, Position } from "reactflow";

const connectionNodeIdSelector = state => state.connectionNodeId;

const { success, error, fetching } = subscriptionAction;

const handleStyle = { left: 10 };

function AskLocationNode({
  props,
  setSidebarState,
  setSelectedNode,
  onImageUpload,
  companyId,
  onTextChange,
  setIdForTemplate,
  setIsChildLoading,
  duplicateNode,
  setErrorsArray,
  isChildLoading,
  deleteParentNode
}) {
  const [parentId, setParentId] = useState();
  // const [selectedOption, setSelectedOption] = useState([]);
  const [dropdownOptions, setDropDownOptions] = useState([]);

  const [valuesObj, setValuesObj] = useState({
    Question: "",
    Attribute1: {},
    Attribute2: {}
  });

  const handleDeleteParentNode = () => {
    deleteParentNode(props?.id);
  };

  const handleGetDropdownData = async () => {
    try {
      // console.log(companyId, "companyId inside function");
      const res = await getAttributDropDownData(companyId);
      if (res.status == "success") {
        // console.log(res, "res from dropdown api");

        setDropDownOptions(res.data);
      } else {
        // console.log(res, "Something went wrong");
      }
    } catch (error) {
      console.log(error, "error while trying to get data for dropdown");
    }
  };

  useEffect(() => {
    if (companyId !== null && companyId !== undefined && companyId !== "") {
      handleGetDropdownData();
    }
  }, [companyId]);

  const handleValueChange = (option, flag) => {
    // console.log({ option });
    // setSelectedOption([...selectedOption, option]);
    // submitSelectedOption(e.target.value);

    if (flag == true) {
      const temp = {
        ...valuesObj,
        Attribute1: {
          id: option?.id,
          type: option?.type,
          name: option?.name
        }
      };

      setValuesObj(temp);
      onTextChange(props?.id, temp);
    } else if (flag == false) {
      const temp = {
        ...valuesObj,
        Attribute2: {
          id: option?.id,
          type: option?.type,
          name: option?.name
        }
      };

      setValuesObj(temp);
      onTextChange(props?.id, temp);
    }
  };
  useEffect(() => {
    if (valuesObj?.Question == "") {
      setErrorsArray(arr => {
        const index = arr.findIndex(x => x?.id === props?.id);
        if (index === -1) {
          return [...arr, { id: props?.id }];
        }
        return arr; // If the error for this component already exists in the array, no need to update
      });
    } else {
      setErrorsArray(arr => {
        const filteredArr = arr.filter(x => x?.id !== props?.id);
        return [...filteredArr]; // Return a new array without the error for the current component
      });
    }
  }, [valuesObj]);

  useEffect(() => {
    if (props?.data?.value && Object.keys(props.data.value).length !== 0) {
      // console.log(props?.data?.value, "setting values obj here");
      setValuesObj(props?.data?.value);
    }
  }, []);

  // console.log(selectedOption, "selected option and values obj", valuesObj);

  const Error = ({ fieldId }) => {
    return (
      <>
        {valuesObj?.Question === "" && (
          <aside
            className="error-message-row"
            style={{ left: "16px", bottom: "3px" }}
          >
            <span className={"error-msg"}>Question is required</span>
          </aside>
        )}
      </>
    );
  };

  const handleChangeData = e => {
    let temp = {
      ...valuesObj,
      Question: e.target.value
    };

    setValuesObj(temp);
    onTextChange(props?.id, temp);
  };

  const connectionNodeId = useStore(connectionNodeIdSelector);
  const isConnecting = !!connectionNodeId;

  return (
    <>
      <>
        <div className="text-updater-node flow-comn-box" onClick={() => {}}>
          <div className="flow-title-box">
            <aside>Message</aside>
            {/* <Handle type="target" className="flow-trigger flow-trigger-fill" /> */}
            <>
              <div className="header-btn-row" style={{ paddingRight: "15px" }}>
                <Tooltip title="Clone">
                  <div
                    onClick={() => {
                      duplicateNode(props?.id);
                    }}
                    className="preview-clone-button"
                  >
                    <i className="fas fa-clone"></i>{" "}
                  </div>
                </Tooltip>
                <Tooltip title="Delete">
                  <div
                    onClick={handleDeleteParentNode}
                    className="preview-clone-button"
                  >
                    <i className="fas fa-trash"></i>{" "}
                  </div>
                </Tooltip>
              </div>
              <Handle
                id={`${props?.id}_attribute`}
                type="source"
                className="flow-trigger"
              ></Handle>
            </>
          </div>
          <div
            className="position-relative"
            onClick={() => {
              //   setSelectedNode(extractParentId(childNodeId));
              // setSidebarState(true)
            }}
            //  className="text-updater-node"
          >
            {/* <div className="flow-comn-close-btn" style={{ cursor: "pointer" }}>
            <i className="fas fa-times-circle"></i>
          </div> */}

            <div className="flow-inner-row">
              <div className="flow-inner-box pb-4">
                <div style={{ position: "relative" }}>
                  Question
                  <TextField
                    value={valuesObj?.Question}
                    minRows={1.5}
                    multiline
                    onChange={e => handleChangeData(e)}
                    placeholder="Enter Question here"
                  />
                  <Error fieldId={"id"} />
                </div>

                <div>
                  <InputLabel id={`demo-simple-select-label-${parentId}`}>
                    Location Latitude Attribute
                  </InputLabel>
                  <Select
                    className="nodrag dropdown-zindex"
                    style={{ width: "200px" }}
                    labelId={`demo-simple-select-label-${parentId}`}
                    id="demo-simple-select"
                    value={parseInt(valuesObj?.Attribute1?.id)}
                    defaultValue={valuesObj?.Attribute1?.id}
                    // multiple
                    // onChange={(e) => handleValueC  hange(e)}
                  >
                    {dropdownOptions && dropdownOptions.length > 0 ? (
                      dropdownOptions.map(option => {
                        return (
                          <MenuItem
                            onClick={() => {
                              handleValueChange(option, true);
                            }}
                            value={option?.id}
                          >
                            {option?.name}
                          </MenuItem>
                        );
                      })
                    ) : (
                      <MenuItem disabled>Nothing To Select</MenuItem>
                    )}
                  </Select>
                </div>
                <div>
                  <InputLabel id={`demo-simple-select-label-${parentId}`}>
                    Location Longitude Attribute
                  </InputLabel>
                  <Select
                    className="nodrag dropdown-zindex"
                    style={{ width: "200px" }}
                    labelId={`demo-simple-select-label-${parentId}`}
                    id="demo-simple-select"
                    value={parseInt(valuesObj?.Attribute2?.id)}
                    label="Age"
                    defaultValue={valuesObj?.Attribute2?.id}
                    // multiple
                    // onChange={(e) => handleValueC  hange(e)}
                  >
                    {dropdownOptions && dropdownOptions.length > 0 ? (
                      dropdownOptions.map(option => {
                        return (
                          <MenuItem
                            onClick={() => {
                              handleValueChange(option, false);
                            }}
                            value={option?.id}
                          >
                            {option?.name}
                          </MenuItem>
                        );
                      })
                    ) : (
                      <MenuItem disabled>Nothing To Select</MenuItem>
                    )}
                  </Select>
                </div>
              </div>{" "}
            </div>
          </div>
          {isConnecting ? (
            <>
              <Handle
                type="target"
                position={Position.Left}
                className="flow-trigger "
                style={{
                  overflow: "hidden",
                  width: "100%", // Adjust the width as needed
                  height: "100%",
                  // height: "110%",
                  background: isConnecting ? "transparent" : "transparent",
                  border: "none",
                  position: "absolute",
                  left: "0px", // Adjust position to the left side of the node
                  top: "50%",
                  zIndex: 9,
                  borderRadius: 0,
                  cursor: isConnecting ? "default" : "move" // Change cursor based on connection state
                }}
                isConnectable={isConnecting} // Disable connection when already connecting
              />
            </>
          ) : (
            <>
              <Handle
                type="target"
                position={Position.Left}
                className="flow-trigger flow-trigger-fill"
                style={{
                  left: "1px",
                  right: "auto"
                }}
                isConnectable={isConnecting} // Disable connection when already connecting
              />
            </>
          )}
        </div>
      </>
    </>
  );
}

export default AskLocationNode;
