import {
  Button,
  InputLabel,
  MenuItem,
  Modal,
  TextField,
  Tooltip
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import Select from "react-select";
import { ModalBody } from "reactstrap";
import subscriptionAction from "redux/subscription/action";
import { getTagsDropDownData, submitNewTagData } from "services/flowServices";
import { Handle, useStore, Position } from "reactflow";

const { success, error, fetching } = subscriptionAction;

const connectionNodeIdSelector = state => state.connectionNodeId;

const handleStyle = { left: 10 };

function TagsNode({
  props,
  setSidebarState,
  setSelectedNode,
  onImageUpload,
  companyId,
  onTextChange,
  setIdForTemplate,
  setIsChildLoading,
  duplicateNode,
  setErrorsArray,
  isChildLoading,
  deleteParentNode
}) {
  const [parentId, setParentId] = useState();
  const [selectedOption, setSelectedOption] = useState(null);
  const [dropdownOptions, setDropDownOptions] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [valuesObj, setValuesObj] = useState({
    id: []
  });
  const [newTagData, setNewTagData] = useState("");
  const [refresh, setRefresh] = useState(false);

  const [previewState, setPreviewState] = useState("");

  const toggleModalState = () => {
    setIsModalOpen(!isModalOpen);
  };
  const handleDeleteParentNode = () => {
    deleteParentNode(props?.id);
  };

  const handleGetDropdownData = async () => {
    try {
      const res = await getTagsDropDownData(companyId);
      if (res.status == "success") {
        // console.log(res, "res from dropdown api");

        if (res?.data?.length > 0) {
          let masterArray = [];
          res?.data?.map(x => {
            let temp = {
              value: x?.id,
              label: x?.name
            };
            masterArray.push(temp);
          });

          setDropDownOptions(masterArray);
        } else {
          let temp = {
            id: null,
            label: "Nothing to select"
          };
          setDropDownOptions(temp);
        }
        // setDropDownOptions(res.data);
      } else {
        // console.log(res, "So    mething went wrong");
      }
    } catch (error) {
      console.log(error, "error while trying to get data for dropdown");
    } finally {
      setRefresh(false);
    }
  };

  useEffect(() => {
    if (refresh == true) {
      handleGetDropdownData();
    }
  }, [refresh]);
  useEffect(() => {
    if (companyId !== null && companyId !== undefined && companyId !== "") {
      handleGetDropdownData();
    }
  }, [companyId]);

  useEffect(() => {
    if (valuesObj?.id == "") {
      setErrorsArray(arr => {
        const index = arr.findIndex(x => x?.id === props?.id);
        if (index === -1) {
          return [...arr, { id: props?.id }];
        }
        return arr; // If the error for this component already exists in the array, no need to update
      });
    } else {
      setErrorsArray(arr => {
        const filteredArr = arr.filter(x => x?.id !== props?.id);
        return [...filteredArr]; // Return a new array without the error for the current component
      });
    }
  }, [valuesObj]);

  useEffect(() => {
    if (props?.data?.value && Object.keys(props.data.value).length !== 0) {
      // let temp = {
      //   id: props?.data?.value?.id,
      //   type: props?.data?.value?.type,
      // };

      setValuesObj({ ...valuesObj, id: props?.data?.value?.id });

      // setSelectedOption(temp);
      // setValuesObj({
      //   id: props?.data?.value?.id,
      //   value: props?.data?.value?.value,
      // });
    }
  }, []);

  const Error = ({ fieldId }) => {
    return (
      <>
        {fieldId == "id" && valuesObj?.id == "" ? (
          <>
            <aside
              className="error-message-row"
              style={{ left: "16px", bottom: "3px" }}
            >
              <span className={"error-msg"}>Please select an option</span>
            </aside>
          </>
        ) : null}
      </>
    );
  };

  const handleSubmitNewTag = async () => {
    try {
      const dataToSend = {
        company_id: companyId,
        tag: newTagData
      };
      // console.log(dataToSend, "new tag data");
      const result = await submitNewTagData(dataToSend);
      //debugger;
      if (result?.status == "success") {
        setRefresh(true);
        toggleModalState();
        success("New Tag added successfuly");
      } else {
        // console.log(result, "result in res");
        error(`${result?.errors}`);
        // error("")
      }
    } catch (error) {
      //debugger;
      console.log(error, "error while trying to submit new tag");
    }
  };
  const handleChangeData = e => {
    let temp = {
      ...valuesObj,
      value: e.target.value
    };

    setValuesObj(temp);
    onTextChange(props?.id, temp);
  };

  const connectionNodeId = useStore(connectionNodeIdSelector);
  const isConnecting = !!connectionNodeId;

  return (
    <>
      <>
        <div className="text-updater-node flow-comn-box" onClick={() => {}}>
          <div className="flow-title-box">
            <aside>Message</aside>
            {/* <Handle type="target" className="flow-trigger flow-trigger-fill" /> */}
            <>
              <div className="header-btn-row" style={{ paddingRight: "15px" }}>
                <Tooltip title="Clone">
                  <div
                    onClick={() => {
                      duplicateNode(props?.id);
                    }}
                    className="preview-clone-button"
                  >
                    <i className="fas fa-clone"></i>{" "}
                  </div>
                </Tooltip>
                <Tooltip title="Delete">
                  <div
                    onClick={handleDeleteParentNode}
                    className="preview-clone-button"
                  >
                    <i className="fas fa-trash"></i>{" "}
                  </div>
                </Tooltip>
              </div>
              <Handle
                id={`${props?.id}_attribute`}
                type="source"
                className="flow-trigger"
              ></Handle>
            </>
          </div>
          <div
            className="position-relative"
            onClick={() => {
              //   setSelectedNode(extractParentId(childNodeId));
              // setSidebarState(true)
            }}
            //  className="text-updater-node"
          >
            {/* <div className="flow-comn-close-btn" style={{ cursor: "pointer" }}>
            <i className="fas fa-times-circle"></i>
          </div> */}

            <div className="flow-inner-row">
              <div className="flow-inner-box pb-4">
                <InputLabel id={`demo-simple-select-label-${parentId}`}>
                  Tags
                </InputLabel>

                <Select
                  name="dropdown"
                  // menuIsOpen
                  // defaultMenuIsOpen
                  options={dropdownOptions}
                  isMulti
                  value={valuesObj?.id}
                  onChange={e => {
                    if (e?.length) {
                      setValuesObj({ ...valuesObj, id: e });
                      onTextChange(props?.id, { ...valuesObj, id: e });
                    } else {
                      setValuesObj({ ...valuesObj, id: [] });
                      onTextChange(props?.id, { ...valuesObj, id: [] });
                    }
                  }}
                  className="nodrag dropdown-zindex"
                  placeholder="Select Data"
                ></Select>

                <Error fieldId={"id"} />
              </div>{" "}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <Button
                  className="flow-comn-btn"
                  variant="contained"
                  onClick={toggleModalState}
                >
                  <i className="fas fa-plus mr-2"></i> Add New Tag
                </Button>
              </div>
              <Modal className="flow-modal" open={isModalOpen}>
                <ModalBody>
                  Create a New tag
                  <TextField
                    fullWidth
                    placeholder="Enter new tag here"
                    value={newTagData}
                    onChange={e => setNewTagData(e?.target?.value)}
                    size="medium"
                  />
                  <div className="modal-btn-row">
                    <button
                      className="comn-btn comn-btn-border"
                      onClick={() => {
                        toggleModalState();
                      }}
                    >
                      Cancel
                    </button>
                    {newTagData !== "" ? (
                      <button
                        className="comn-btn"
                        style={{ cursor: "pointer" }}
                        onClick={handleSubmitNewTag}
                      >
                        Submit
                      </button>
                    ) : (
                      <button className="comn-btn" disabled>
                        Submit
                      </button>
                    )}
                  </div>
                </ModalBody>
              </Modal>
            </div>
          </div>
          {isConnecting ? (
            <>
              <Handle
                type="target"
                position={Position.Left}
                className="flow-trigger "
                style={{
                  overflow: "hidden",
                  width: "100%", // Adjust the width as needed
                  height: "100%",
                  // height: "110%",
                  background: isConnecting ? "transparent" : "transparent",
                  border: "none",
                  position: "absolute",
                  left: "0px", // Adjust position to the left side of the node
                  top: "50%",
                  zIndex: 9,
                  borderRadius: 0,
                  cursor: isConnecting ? "default" : "move" // Change cursor based on connection state
                }}
                isConnectable={isConnecting} // Disable connection when already connecting
              />
            </>
          ) : (
            <>
              <Handle
                type="target"
                position={Position.Left}
                className="flow-trigger flow-trigger-fill"
                style={{
                  left: "1px",
                  right: "auto"
                }}
                isConnectable={isConnecting} // Disable connection when already connecting
              />
            </>
          )}
        </div>
      </>
    </>
  );
}

export default TagsNode;
