import { Input, TextField } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { Handle, Position } from "reactflow";
import subscriptionAction from "redux/subscription/action";

const handleStyle = { left: 10 };

const HandleStyleCheck = {
  top: {
    // right: -10,
    // top: 15,
    background: "#555",
    minWidth: 20,
    height: 20,
    borderRadius: 4,
    placeItems: "center",
    display: "grid",
    color: "#fff",
    zIndex: 2
  },
  bottom: {
    right: -10,
    top: 43,
    background: "#555",
    minWidth: 20,
    height: 20,
    borderRadius: 4,
    placeItems: "center",
    display: "grid",
    color: "#fff",
    zIndex: 2
  }
};
function CustomButtonNode({
  props,
  text,
  setSelectedNode,
  setSidebarState,
  onTextChange,
  addExtraNode,
  deleteChildNode
}) {
  // const{id,data} = props
  // const {data} = props

  const { success, error, fetching } = subscriptionAction;

  const [childNodeId, setChildNodeId] = useState("");
  const [details, setDetails] = useState();
  const [textBoxHeight, setTextBoxHeight] = useState("46");
  const [textValue, setTextValue] = useState("");

  useEffect(() => {
    if (props?.id) {
      setChildNodeId(props?.id);
    }
    if (props?.data) {
      setTextValue(props?.data?.value);
    }
  });

  const onChange = evt => {
    let newText;

    if (evt?.target?.value?.length >= 20) {
      newText = evt.target.value.slice(0, 20);
      error("Max 20 characters are allowed");
    } else {
      newText = evt.target.value;
    }
    setDetails(prevDetails => ({ ...prevDetails, name: newText }));
    // console.log(data,"Data. id")
    const newHeight = evt.target.scrollHeight;

    const heightDifference = newHeight - textBoxHeight;
    console.log(
      newHeight,
      "newHeight",
      heightDifference,
      "heightDifference",
      textBoxHeight,
      "textBoxHeight"
    );
    setTextBoxHeight(newHeight);
    setTextValue(newText);
    // console.log("after height update");
    onTextChange(props.id, newText, heightDifference);
  };
  useEffect(() => {
    console.log({ textBoxHeight });
  }, [textBoxHeight]);

  function extractParentId(childId) {
    // Split the childId by "-"
    const parts = childId.split("-");

    // If there is more than one part after splitting, it means it follows the pattern
    if (parts.length > 1) {
      // The first part is the parent id
      return parts[0];
    } else {
      // If there is only one part, it might be the parent id itself
      return childId;
    }
  }

  console.log(extractParentId(childNodeId), "parent id from child node id");

  const handleDeleteChildNode = () => {
    deleteChildNode(childNodeId, extractParentId(childNodeId), "customButton");
  };

  const handleAddExtraNode = type => {
    addExtraNode(type, extractParentId(childNodeId));
  };
  return (
    <div
      className="nodrag position-relative"
      onClick={() => {
        setSelectedNode(extractParentId(childNodeId));
        // setSidebarState(true)
      }}
      //  className="text-updater-node"
    >
      {/* <span style={{ textAlign: "center" }}>{details?.name}</span> */}

      {/* <div style={{display:"flex", justifyContent:"center"}}>
                <label htmlFor="text">Child Node : {childNodeId}</label>
                <Input onChange={onChange} type='text' />

            </div> */}

      <div
        className="flow-comn-close-btn"
        onClick={() => {
          handleDeleteChildNode();
        }}
        style={{ cursor: "pointer" }}
      >
        <i className="fas fa-times-circle"></i>
      </div>

      <div className="flow-inner-row">
        <div className="flow-inner-box">
          <Input
            size="large"
            multiline
            value={textValue}
            placeholder="Enter your button title here"
            minRows={2}
            onChange={e => {
              onChange(e);
            }}
          />
          <Handle type="source" className="flow-trigger"></Handle>

          {/* <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Deleniti aperiam et esse cum fugit sit, porro, nam eligendi molestiae quae ad eveniet! A reiciendis, laudantium velit sint incidunt tempore accusantium.</p> */}
        </div>
        {/* <div style={{ display: "flex", justifyContent: "center" }}>
          <i
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              handleAddExtraNode("customButton");
            }}
            className="fas fa-plus-circle"
          ></i>
        </div> */}
      </div>
      {/* <Handle draggable='false' style={{...HandleStyleCheck.top}}  isConnectableStart='true' type="source" > */}
      {/* <i className="fas fa-circle"></i> */}
      {/* </Handle> */}
    </div>
  );
}

export default CustomButtonNode;
