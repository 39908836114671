import React, { useEffect, useState } from "react";
import { Button, Grid, TextField, Tooltip } from "@mui/material";
import { Handle, useStore, Position } from "reactflow";
import Enhancer from "../Enhancers/NewListEnhancer";
import SubscriptionAction from "redux/subscription/action";

const connectionNodeIdSelector = state => state.connectionNodeId;

const NewListNode = ({
  props,
  setSidebarState,
  setSelectedNode,
  deleteParentNode,
  onTextChange,
  duplicateNode,
  setPreviewState,
  setValues,
  errors,
  setErrorsArray,
  touched,
  values,
  submitCount,
  handleBlur
}) => {
  const { success, error, fetching } = SubscriptionAction;

  const [parentId, setParentId] = useState();
  const [allValues, setAllValues] = useState({
    header: "",
    body: "",
    footer: "",
    title: "List",
    sections: []
  });

  function extractParentId(childId) {
    const parts = childId.split("-");
    if (parts.length > 1) {
      return parts[0];
    } else {
      return childId;
    }
  }

  useEffect(() => {
    if (props?.id) {
      setParentId(extractParentId(props?.id));
    }
    if (props?.data?.value && Object.keys(props.data.value).length !== 0) {
      setAllValues(props?.data?.value);
      setValues(props?.data?.value);
    } else {
      setValues(allValues);
    }
  }, []);

  useEffect(() => {
    // console.log(errors, "if any from formik");
    // console.log(touched, "touched if any from formik  ");
    if (Object.keys(errors).length !== 0) {
      // console.log(errors, "errors array if");
      setErrorsArray(arr => {
        const index = arr.findIndex(x => x?.id === props?.id);
        if (index === -1) {
          return [...arr, { id: props?.id }];
        }
        return arr; // If the error for this component already exists in the array, no need to update
      });
    } else {
      // console.log(errors, "errors array else");
      setErrorsArray(arr => {
        const filteredArr = arr.filter(x => x?.id !== props?.id);
        return [...filteredArr]; // Return a new array without the error for the current component
      });
    }
  }, [errors]);

  function generateUniqueId() {
    const alphanumericCharacters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let uniqueKey = "";

    for (let i = 0; i < 10; i++) {
      const randomIndex = Math.floor(
        Math.random() * alphanumericCharacters.length
      );
      uniqueKey += alphanumericCharacters.charAt(randomIndex);
    }

    return uniqueKey;
  }
  const addTextField = () => {
    const isFirstSection = allValues.sections.length === 0;

    const newFields = Array.from({ length: 1 }, (_, i) => ({
      id: `textField${allValues.sections.length + i + 1}`,
      title: isFirstSection ? "Title" : "",
      item: [
        {
          index: 1,
          itemTitle: "",
          itemDescription: "",
          uniqueId: generateUniqueId()
        }
      ]
    }));
    setAllValues(prevState => {
      let temp = {
        ...prevState,
        sections: [...prevState.sections, ...newFields]
      };
      onTextChange(props?.id, temp);
      setValues(temp);
      return temp;
    });
  };

  const handleChange = (id, field, value, flag) => {
    let maxLength;

    if (id == "body") {
      maxLength = 1024;
    } else if (id == "header" || id == "footer") {
      maxLength = 60;
    } else if (id == "title") {
      maxLength = 20;
    }

    let newVal = value;
    if (newVal?.length > maxLength) {
      error(`Only ${maxLength} characters are allowed in ${id}`);
      newVal = newVal.slice(0, maxLength);
    }

    if (flag === true) {
      const temp = {
        ...allValues,
        [id]: newVal
      };
      onTextChange(props?.id, temp);
      setValues(temp);
      setAllValues(temp);
    } else {
      const updatedSections = allValues.sections.map(section =>
        section.id === id ? { ...section, [field]: value } : section
      );
      // setAllValues((prevState) => ({
      //   ...prevState,
      //   sections: updatedSections,
      // }));
      const temp = { ...allValues, sections: updatedSections };
      setValues(temp);
      setAllValues(temp);
      onTextChange(props?.id, temp);
    }
  };

  const handleDeleteParentNode = () => {
    deleteParentNode(props?.id);
  };

  const setAdditionalFieldsData = (id, value, index, flag, ind) => {
    let maxLength = 0;
    if (flag === "title") {
      maxLength = 20;
    } else if (flag === "itemTitle") {
      maxLength = 20;
    } else if (flag === "itemDescription") {
      maxLength = 72;
    }

    // Truncate the value if it exceeds the maxLength
    if (value?.length > maxLength) {
      error(`Maximum ${maxLength} characters allowed in ${flag}`);
      value = value.slice(0, maxLength);
    }
    const tempData = allValues?.sections?.map((section, idx) => {
      if (idx === index) {
        if (flag === "title") {
          return {
            ...section,
            title: value
          };
        } else if (flag === "itemTitle") {
          return {
            ...section,
            item: section?.item?.map((item, i) =>
              i == ind ? { ...item, itemTitle: value } : item
            )
          };
        } else if (flag === "itemDescription") {
          return {
            ...section,
            item: section?.item?.map((item, i) =>
              i == ind ? { ...item, itemDescription: value } : item
            )
          };
        }
      }
      return section;
    });

    // setAllValues((prevState) => ({
    //   ...prevState,
    //   sections: tempData,
    // }));
    const temp = {
      ...allValues,
      sections: tempData
    };
    setValues(temp);
    setAllValues(temp);
    onTextChange(props?.id, temp);
  };

  const Error = props => {
    const field1 = props.field;
    if (errors[field1] || touched[field1] || submitCount > 0) {
      // console.log("inside if any from formik");
      return (
        <>
          {/* <p className={`${props.class ? props.class : "error-msg w-100"}`}> */}
          {/* </p> */}
          <aside className="error-message-row">
            <span className={"error-msg"}>{errors[field1]}</span>
          </aside>
        </>
      );
    } else {
      return <span />;
    }
  };

  // Modified Error component to handle errors for buttons
  const ButtonError = ({ index, flag, index2 }) => {
    // console.log("if any from formik errors:", errors, "index",index,"flaf",flag,"index 2",index2);
    // console.log("if any from formik touched:", touched);

    if ((errors?.sections && errors?.sections[index]) || submitCount > 0) {
      // console.log(" if any from formik Error condition satisfied.");
      if (flag == "title") {
        return (
          <aside className="error-message-row">
            <span className={"error-msg"}>
              {errors?.sections[index]?.title}
            </span>
          </aside>
        );
      } else if (flag == "retailerId") {
        return (
          <aside className="error-message-row">
            <span className="error-msg">
              {errors?.sections[index]?.item &&
              errors?.sections[index]?.item.length > 0
                ? errors?.sections[index]?.item[index2]?.itemTitle
                : null}
            </span>
          </aside>
        );
      }
      // return (
      //   <aside className="error-message-row">
      //     <span className={props.class ? props.class : "error-msg"}>
      //       {flag == "title"
      //         ? errors.sections[index].title
      //         : Array.isArray(errors.sections[index].item)
      //         ? errors.sections[index].item[index2]?.value
      //         : flag == "retailerId"
      //         ? errors?.section[index]
      //         : Array.isArray(errors?.sections[index].item)
      //         ? errors.sections[index].item[index2.itemTitle]
      //         : null}
      //     </span>
      //   </aside>
      // );
    } else {
      // console.log("if any from formik No error.");
      return <span />;
    }
  };

  const addRetailerField = fieldId => {
    const updatedFields = allValues.sections.map(field =>
      field.id === fieldId
        ? {
            ...field,
            item: [
              ...field.item,
              {
                index: field.item.length + 1,
                itemTitle: "",
                itemDescription: "",
                uniqueId: generateUniqueId()
              }
            ]
          }
        : field
    );
    // setAllValues((prevState) => ({
    //   ...prevState,
    //   sections: updatedFields,
    // }));
    const temp = {
      ...allValues,
      sections: updatedFields
    };
    setValues(temp);
    setAllValues(temp);
    onTextChange(props?.id, temp);
  };

  const removeRetailer = (pIndex, cIndex) => {
    const updatedData = allValues.sections.map((section, idx) => {
      if (idx === pIndex) {
        return {
          ...section,
          item: section.item.filter((item, i) => i !== cIndex)
        };
      }
      return section;
    });
    // setAllValues((prevState) => ({
    //   ...prevState,
    //   sections: updatedData,
    // }));
    const temp = {
      ...allValues,
      sections: updatedData
    };
    setValues(temp);
    setAllValues(temp);
    onTextChange(props?.id, temp);
  };

  useEffect(() => {
    // console.log(errors, "if any multi product");
  }, [errors]);

  const removeAllRetailerForParent = index => {
    const updatedData = allValues.sections.filter((_, idx) => idx !== index);
    // setAllValues((prevState) => ({
    //   ...prevState,
    //   sections: updatedData,
    // }));
    const temp = {
      ...allValues,
      sections: updatedData
    };
    setValues(temp);
    setAllValues(temp);
    onTextChange(props?.id, temp);
  };

  const connectionNodeId = useStore(connectionNodeIdSelector);
  const isConnecting = !!connectionNodeId;

  return (
    <>
      <div className="text-updater-node flow-comn-box" onClick={() => {}}>
        <div className="flow-title-box">
          <aside>New list node</aside>
          {/* <Handle type="target" className="flow-trigger flow-trigger-fill" /> */}
          {props?.type !== "Template" ? (
            <>
              <div className="header-btn-row">
                <Tooltip title="Preview">
                  <div
                    onClick={() => {
                      setSelectedNode(props?.id);
                      setPreviewState(true);
                    }}
                    className="preview-eye-button"
                  >
                    <i className="fas fa-eye"></i>
                  </div>
                </Tooltip>

                <Tooltip title="Clone">
                  <div
                    onClick={() => {
                      duplicateNode(props?.id);
                    }}
                    className="preview-clone-button"
                  >
                    <i className="fas fa-clone"></i>{" "}
                  </div>
                </Tooltip>
                <Tooltip title="Delete">
                  <div
                    onClick={handleDeleteParentNode}
                    className="preview-clone-button"
                  >
                    <i className="fas fa-trash"></i>{" "}
                  </div>
                </Tooltip>
              </div>
            </>
          ) : null}
        </div>

        <div className="flow-inner-row flow-textarea-row">
          {["header", "body", "footer", "title"].map((field, index) => (
            <>
              <aside>
                <TextField
                  className="textfield-padding-small"
                  size="large"
                  multiline={true}
                  id={field}
                  onBlur={handleBlur}
                  minRows={field == "body" ? 4 : field == "title" ? 2 : 1}
                  placeholder={
                    field == "body" || field == "title"
                      ? `${field}`
                      : `${field}(optional)`
                  }
                  value={allValues[field]}
                  onChange={e =>
                    handleChange(field, "value", e.target.value, true)
                  }
                />
                <Error field={field} />
                <div className="counter-class">
                  {allValues[field]?.length !== 0
                    ? `${allValues[field]?.length}/${
                        field == "body"
                          ? 1024
                          : field == "header" || field == "footer"
                          ? 60
                          : field == "title"
                          ? 20
                          : null
                      }`
                    : null}
                </div>
              </aside>
            </>
          ))}

          {allValues.sections.map((field, index) => (
            <div
              style={{ position: "relative " }}
              className="text-btn-add-row mt-3"
            >
              <p
                className="flow-comn-close-btn"
                style={{ cursor: "pointer" }}
                onClick={() => removeAllRetailerForParent(index)}
              >
                <i className="fas fa-times-circle"></i>
              </p>
              <div style={{ position: "relative" }}>
                <TextField
                  className="textfied-padding-small"
                  label={`Section Title ${index + 1}`}
                  value={field?.title}
                  multiline={true}
                  id="sections"
                  onBlur={handleBlur}
                  minRows={1}
                  onChange={e =>
                    setAdditionalFieldsData(
                      field.id,
                      e.target.value,
                      index,
                      "title"
                    )
                  }
                />
                <div className="counter-class">
                  {field.title?.length !== 0
                    ? `${field.title?.length}/20`
                    : null}
                </div>
                <ButtonError index={index} flag="title" />
              </div>
              <p>
                {field.item &&
                  field.item.length > 0 &&
                  field.item.map((x, ind) => {
                    return (
                      <>
                        <div style={{ position: "relative" }} className="mt-2">
                          <React.Fragment key={ind}>
                            <TextField
                              className="textfield-padding-small"
                              label={`Item title ${ind + 1}`}
                              value={x?.itemTitle}
                              multiline
                              minRows={2}
                              style={{
                                backgroundColor: "white",
                                width: "100%"
                              }}
                              onChange={e =>
                                setAdditionalFieldsData(
                                  field.id,
                                  e.target.value,
                                  index,
                                  "itemTitle",
                                  ind
                                )
                              }
                            />
                            <ButtonError
                              index={index}
                              flag="retailerId"
                              index2={ind}
                            />

                            <p
                              className="flow-comn-close-btn"
                              onClick={() => removeRetailer(index, ind)}
                              style={{ cursor: "pointer" }}
                            >
                              <i className="fas fa-times-circle"></i>
                            </p>
                          </React.Fragment>
                          <div className="counter-class">
                            {x.itemTitle?.length !== 0
                              ? `${x?.itemTitle?.length}/20`
                              : null}
                          </div>
                        </div>
                        <div style={{ position: "relative" }} className="mt-2">
                          <React.Fragment key={ind}>
                            <TextField
                              className="textfield-padding-small"
                              label={`Item description ${ind + 1}`}
                              value={x?.itemDescription}
                              multiline
                              minRows={4}
                              style={{
                                backgroundColor: "white",
                                width: "100%"
                              }}
                              onChange={e =>
                                setAdditionalFieldsData(
                                  field.id,
                                  e.target.value,
                                  index,
                                  "itemDescription",
                                  ind
                                )
                              }
                            />
                            {/* <ButtonError
                              index={index}
                              flag="retailerId"
                              index2={ind}
                            /> */}
                            <Handle
                              id={`${props?.id}_${field.id}_${ind}`}
                              type="source"
                              className="flow-trigger"
                            ></Handle>
                          </React.Fragment>
                          <div className="counter-class">
                            {x.itemDescription?.length !== 0
                              ? `${x?.itemDescription?.length}/72`
                              : null}
                          </div>
                        </div>
                      </>
                    );
                  })}
                <Button
                  className="flow-comn-btn"
                  variant="contained"
                  onClick={() => {
                    addRetailerField(field.id);
                  }}
                >
                  <i className="fas fa-plus mr-2"></i> Add item
                </Button>
              </p>
            </div>
          ))}

          <Button
            className="flow-comn-btn"
            variant="contained"
            onClick={addTextField}
          >
            <i className="fas fa-plus mr-2"></i> Add Another Section
          </Button>
          {/* Displaying all values of text fields */}
          {/* <pre>{JSON.stringify(allValues, null, 2)}</pre> */}
        </div>

        {isConnecting ? (
          <>
            <Handle
              type="target"
              position={Position.Left}
              className="flow-trigger "
              style={{
                overflow: "hidden",
                width: "100%", // Adjust the width as needed
                height: "100%",
                // height: "110%",
                background: isConnecting ? "transparent" : "transparent",
                border: "none",
                position: "absolute",
                left: "0px", // Adjust position to the left side of the node
                top: "50%",
                zIndex: 9,
                borderRadius: 0,
                cursor: isConnecting ? "default" : "move" // Change cursor based on connection state
              }}
              isConnectable={isConnecting} // Disable connection when already connecting
            />
          </>
        ) : (
          <>
            <Handle
              type="target"
              position={Position.Left}
              className="flow-trigger flow-trigger-fill"
              style={{
                left: "1px",
                right: "auto"
              }}
              isConnectable={isConnecting} // Disable connection when already connecting
            />
          </>
        )}
      </div>
    </>
  );
};

export default Enhancer(NewListNode);
