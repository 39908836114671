import React, { Fragment } from "react";

import { FallingLines } from "react-loader-spinner";
const SpinnerLoader = props => {
  return (
    // <>
    // <div style={{position:"relative", minHeight: "100vh"}}>
    // <div className='loader-child-class' style={{}}>
    // <FallingLines
    //      color="#4fa94d"
    //      width="100"
    //      visible={true}
    //      ariaLabel="falling-circles-loading"
    // />
    // </div>
    // </div>
    // </>

    <>
      <div>
        <div className="myuihub-loader2"></div>
      </div>
    </>
  );
};

export default SpinnerLoader;
