import { useCallback, useEffect, useState } from "react";
import { Handle, Position } from "reactflow";
import defaultImageForPdf from "../../../../assets/images/pdf_placeholder_image.png";
import defaultImageForVideo from "../../../../assets/images/defaultVideoPic.png";
import SpinnerLoader from "components/common/SpinnerLoader";
import defaultImage from "../../../../assets/images/defaultImagePic.png";
import AiCubixLogo from "../../../../assets/images/aiCubixLogo.png";

const handleStyle = { left: 10 };

function CustomSideBarPreview({
  props,
  setPreviewForWhatsapp,
  selectedNode,
  isChildLoading
  // childOfThisNode,
}) {
  // const{id,data} = props
  // const {data} = props

  const [parentId, setParentId] = useState();
  const [parentNodeType, setParentNodeType] = useState("");
  const [lengthOfButtons, setLengthOfButtons] = useState(0);
  const [toDisplay, setToDisplay] = useState(false);

  console.log(selectedNode, "selectedNode");

  useEffect(() => {
    setParentNodeType(selectedNode?.type);
    console.log(selectedNode?.type, "selectedNode?.data?.type");
    // if (selectedNode?.data?.type == "Text + Buttons") {
    //   const tempArray = childOfThisNode.filter((x) => {
    //     return x?.type == "customButton";
    //   });
    //   setLengthOfButtons(tempArray?.length);
    // }
  }, [selectedNode]);
  useEffect(() => {
    if (props?.id) {
      setParentId(props?.id);
    }

    if (selectedNode) {
      setToDisplay(true);
    } else {
      setPreviewForWhatsapp(false);
    }
  }, []);
  console.log(selectedNode, "selected node here");
  useEffect(() => {
    setToDisplay(!isChildLoading);
  }, [isChildLoading]);
  return (
    <>
      {toDisplay == true ? (
        <>
          <div>
            <div
              style={{
                width: "300px",
                height: "100vh",
                backgroundColor: "#ECE5DD",
                position: "relative"
              }}
            >
              <div
                style={{
                  width: "100%",
                  backgroundColor: "#075E54",
                  height: "50px",
                  display: "flex",
                  alignItems: "center"
                }}
              >
                <i
                  className="fas fa-arrow-left pl-3"
                  style={{
                    justifyContent: "flex-start",
                    cursor: "pointer",
                    color: "white"
                  }}
                  onClick={() => {
                    setPreviewForWhatsapp(false);
                  }}
                ></i>

                <div
                  style={{ display: "flex", color: "white" }}
                  className="pl-3"
                >
                  <img
                    src={AiCubixLogo}
                    style={{
                      borderRadius: "50%",
                      width: "20px",
                      height: "20px",
                      objectFit: "cover",
                      marginRight: "5px",
                      marginLeft: "-5px"
                    }}
                  ></img>
                  <span>+917984720597</span>
                </div>

                <i
                  onClick={() => setPreviewForWhatsapp(false)}
                  style={{
                    color: "white",
                    display: "flex",
                    justifyContent: "flex-end",
                    cursor: "pointer"
                  }}
                  className="fas fa-trash ml-auto mr-3"
                ></i>
              </div>

              {/* Different node types different preview */}
              {parentNodeType == "Text" ? (
                <>
                  <div className="flow-whatsapp-preview-container">
                    <div className="flow-whatsapp-preview-box">
                      <p style={{ wordBreak: "break-all" }}>
                        {selectedNode?.data?.value?.text}
                      </p>
                    </div>
                  </div>
                </>
              ) : parentNodeType == "textButtons" ? (
                <>
                  <div className="flow-whatsapp-preview-container">
                    <div className="flow-whatsapp-preview-box">
                      <span style={{ fontSize: "16px" }}>
                        {selectedNode?.data?.value?.header}
                      </span>
                      <p className="pt-2" style={{ wordBreak: "break-all" }}>
                        {selectedNode?.data?.value?.body}
                      </p>
                      <p
                        className="pt-2"
                        style={{ fontSize: "12px", color: "#666666" }}
                      >
                        {selectedNode?.data?.value?.footer}
                      </p>
                    </div>
                    {selectedNode?.data?.value?.buttons?.length &&
                    selectedNode?.data?.value?.buttons?.length == 2 ? (
                      <>
                        <div className="flow-whatsapp-preview-btn-row">
                          {selectedNode?.data?.value.buttons.map(x => {
                            return (
                              <>
                                <div>{x.value}</div>
                              </>
                            );
                          })}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="flow-whatsapp-preview-btn-row flow-full-btn">
                          {selectedNode?.data?.value?.buttons?.length
                            ? selectedNode?.data?.value.buttons.map(x => {
                                return (
                                  <>
                                    <div>{x?.value}</div>
                                  </>
                                );
                              })
                            : null}
                        </div>
                      </>
                    )}
                    {/* <div className="flow-whatsapp-preview-btn-row">
              <div>test</div>
              <div>test</div>
            </div>
            <div className="flow-whatsapp-preview-btn-row flow-full-btn">
              <div>test</div>
              <div>test</div>
              <div>test</div>
            </div> */}
                  </div>
                </>
              ) : parentNodeType == "mediaButtons" ? (
                <>
                  <div className="flow-whatsapp-preview-container">
                    <div className="flow-whatsapp-preview-box">
                      {selectedNode?.data?.value.isPdf == true ? (
                        <>
                          <img src={defaultImageForPdf} alt="" />
                        </>
                      ) : selectedNode?.data?.value.isVideo == true ? (
                        <>
                          <img src={defaultImageForVideo} alt="" />
                        </>
                      ) : (
                        <>
                          <img
                            src={selectedNode?.data?.value.imageLink}
                            alt=""
                          />
                        </>
                      )}
                      <p>{selectedNode?.data?.value?.body}</p>
                    </div>
                    {selectedNode?.data?.value?.buttons?.length &&
                    selectedNode?.data?.value?.buttons.length == 2 ? (
                      <>
                        <div className="flow-whatsapp-preview-btn-row">
                          {selectedNode?.data?.value.buttons.map(x => {
                            return (
                              <>
                                <div>{x?.value}</div>
                              </>
                            );
                          })}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="flow-whatsapp-preview-btn-row flow-full-btn">
                          {selectedNode?.data?.value?.buttons?.length
                            ? selectedNode?.data?.value.buttons.map(x => {
                                return (
                                  <>
                                    <div>{x?.value}</div>
                                  </>
                                );
                              })
                            : null}
                        </div>
                      </>
                    )}
                  </div>
                </>
              ) : parentNodeType == "NewListNode" ? (
                <>
                  <div className="flow-whatsapp-preview-container">
                    <div className="flow-whatsapp-preview-box">
                      {/* <img src={defaultImageForPdf} alt="" /> */}
                      <span style={{ fontSize: "16px" }}>
                        {selectedNode?.data?.value?.header}
                      </span>
                      <p className="pt-2">{selectedNode?.data?.value?.body}</p>
                      <p
                        className="pt-2"
                        style={{ color: "#666666", fontSize: "12px" }}
                      >
                        {selectedNode?.data?.value?.footer}
                      </p>
                      <div
                        style={{ color: "#0041d0", cursor: "pointer" }}
                        className="flow-link-text"
                      >
                        {" "}
                        <i className="fas fa-list"></i>{" "}
                        {selectedNode?.data?.value?.title}
                      </div>
                    </div>
                  </div>
                </>
              ) : parentNodeType == "singleProduct" ? (
                <>
                  <div className="flow-whatsapp-preview-container">
                    <div className="flow-whatsapp-preview-box">
                      <div style={{ backgroundColor: "#FAF9F6" }}>
                        <img
                          style={{ padding: "10px" }}
                          src={defaultImage}
                          alt=""
                        />
                        <p>
                          <b>Product Title</b>
                        </p>
                        <p>$ Price of product</p>
                      </div>
                      <p className="pt-2">{selectedNode?.data?.value.body}</p>
                      <p
                        className="pt-2"
                        style={{ fontSize: "12px", color: "#666666" }}
                      >
                        {selectedNode?.data?.value.footer}
                      </p>
                      <div
                        style={{ color: "#0041d0", cursor: "pointer" }}
                        className="flow-link-text"
                      >
                        View
                      </div>
                    </div>
                  </div>
                </>
              ) : parentNodeType == "multiProduct" ? (
                <>
                  <div className="flow-whatsapp-preview-container">
                    <div className="flow-whatsapp-preview-box">
                      <div className="flow-image-header-row">
                        <img src={defaultImage} alt="" />
                        <div>
                          <p
                            style={{
                              justifyContent: "flex-start",
                              wordBreak: "break-all"
                            }}
                          >
                            {selectedNode?.data?.value.header}
                          </p>
                          <p>2 items</p>
                        </div>
                      </div>
                      <p className="pt-2" style={{ wordBreak: "break-all" }}>
                        {selectedNode?.data?.value.body}
                      </p>
                      <p className="pt-2" style={{ color: "#666666" }}>
                        {selectedNode?.data?.value.footer}
                      </p>
                      <div
                        style={{ color: "#0041d0", cursor: "pointer" }}
                        className="flow-link-text"
                      >
                        View Items
                      </div>
                    </div>
                  </div>
                </>
              ) : parentNodeType == "Template" ? (
                <>
                  <div style={{ textAlign: "center" }} className="pt-5">
                    Preview not available for template
                  </div>
                </>
              ) : parentNodeType == "textLink" ? (
                <>
                  <div className="flow-whatsapp-preview-container">
                    <div className="flow-whatsapp-preview-box">
                      <span style={{ fontSize: "16px" }}>
                        {selectedNode?.data?.value.header}
                      </span>
                      <p className="pt-2" style={{ wordBreak: "break-all" }}>
                        {selectedNode?.data?.value.body}
                      </p>
                      <p
                        className="pt-2"
                        style={{ fontSize: "12px", color: "#666666" }}
                      >
                        {selectedNode?.data?.value.footer}
                      </p>
                      <div
                        className="flow-link-text"
                        style={{ color: "#0041d0" }}
                      >
                        <i
                          className="fas fa-share-square"
                          style={{ marginRight: "5px" }}
                        ></i>
                        {selectedNode?.data?.value.buttonTitle}
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
              {/* <div className="flow-whatsapp-preview-container">
            <div className="flow-whatsapp-preview-box">
              <img src={defaultImageForPdf} alt="" />
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since
              </p>
              <div className="flow-link-text">link here</div>
            </div>
            <div className="flow-whatsapp-preview-btn-row">
              <div>test</div>
              <div>test</div>
            </div>
            <div className="flow-whatsapp-preview-btn-row flow-full-btn">
              <div>test</div>
              <div>test</div>
              <div>test</div>
            </div>
          </div> */}
            </div>
          </div>
        </>
      ) : (
        <>
          <div>
            <div
              style={{
                width: "250px",
                height: "100vh",
                backgroundColor: "#ECE5DD",
                position: "relative"
              }}
            >
              <div
                style={{
                  width: "100%",
                  backgroundColor: "#075E54",
                  height: "50px",
                  display: "flex",
                  alignItems: "center"
                }}
              >
                <i
                  className="fas fa-arrow-left pl-3"
                  style={{
                    justifyContent: "flex-start",
                    cursor: "pointer",
                    color: "white"
                  }}
                ></i>

                <div
                  style={{ display: "flex", color: "white" }}
                  className="pl-3"
                >
                  <img
                    src={defaultImageForPdf}
                    style={{
                      borderRadius: "50%",
                      width: "20px",
                      height: "20px",
                      objectFit: "cover",
                      marginRight: "5px"
                    }}
                  ></img>
                  <span>+917985350928</span>
                </div>

                <i
                  onClick={() => setPreviewForWhatsapp(false)}
                  style={{
                    color: "white",
                    display: "flex",
                    justifyContent: "flex-end",
                    cursor: "pointer"
                  }}
                  className="fas fa-trash ml-auto mr-3"
                ></i>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingTop: "50px"
                }}
              >
                <SpinnerLoader />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default CustomSideBarPreview;
