import React, { useEffect, useState } from "react";
import { Button, Grid, TextField, Tooltip } from "@mui/material";
import { Handle } from "reactflow";
import subscriptionAction from "redux/subscription/action";
import Enhancer from "../Enhancers/TextListEnhancer";

const TestingListNode = ({
  props,
  setSidebarState,
  setSelectedNode,
  deleteParentNode,
  onTextChange,
  duplicateNode,
  setPreviewState,
  setValues,
  errors,
  touched,
  setErrorsArray,
  values,
  submitCount,
  handleBlur
}) => {
  const { success, error, fetching } = subscriptionAction;

  useEffect(() => {
    // console.log(errors, "if any from formik");
    // console.log(touched, "touched if any from formik  ");
    if (Object.keys(errors).length !== 0) {
      // console.log(errors, "errors array list if");
      setErrorsArray(arr => {
        const index = arr.findIndex(x => x?.id === props?.id);
        if (index === -1) {
          return [...arr, { id: props?.id }];
        }
        return arr; // If the error for this component already exists in the array, no need to update
      });
    } else {
      // console.log(errors, "errors array list else");
      setErrorsArray(arr => {
        const filteredArr = arr.filter(x => x?.id !== props?.id);
        return [...filteredArr]; // Return a new array without the error for the current component
      });
    }
  }, [errors]);
  const [parentId, setParentId] = useState();
  const [allValues, setAllValues] = useState({
    body: "",
    header: "",
    footer: "",
    title: "",
    sections: []
  });

  function extractParentId(childId) {
    const parts = childId.split("-");
    if (parts.length > 1) {
      return parts[0];
    } else {
      return childId;
    }
  }

  useEffect(() => {
    if (props?.id) {
      setParentId(extractParentId(props?.id));
    }
    if (props?.data?.value && Object.keys(props.data.value).length !== 0) {
      setAllValues(props?.data?.value);
      setValues(props?.data?.value);
    } else {
      setValues(allValues);
    }
  }, []);

  useEffect(() => {
    // console.log(errors, "if any from formik inside list ");
  }, [errors]);

  function generateUniqueId() {
    const alphanumericCharacters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let uniqueKey = "";
    for (let i = 0; i < 10; i++) {
      const randomIndex = Math.floor(
        Math.random() * alphanumericCharacters.length
      );
      uniqueKey += alphanumericCharacters.charAt(randomIndex);
    }
    return uniqueKey;
  }

  const addTextField = () => {
    const newFields = Array.from({ length: 1 }, (_, i) => ({
      id: `textField${allValues.sections.length + i + 1}`,
      title: "",
      description: "",
      uniqueId: generateUniqueId()
    }));
    setAllValues(prevState => {
      let temp = {
        ...prevState,
        sections: [...prevState.sections, ...newFields]
      };
      setValues(temp);
      return temp;
    });
  };

  const handleChange = (id, field, value, flag) => {
    if (flag == true) {
      if (id == "title" && value.length > 20) {
        error("Max 20 characters are allowed");
        const temp = {
          ...allValues,
          [id]: value.length > 20 ? value.slice(0, 20) : value
        };
        onTextChange(props?.id, temp);
        setValues(temp);
        setAllValues(temp);
      } else {
        const temp = {
          ...allValues,
          [id]: value
        };
        onTextChange(props?.id, temp);
        setValues(temp);
        setAllValues(temp);
      }
    } else {
      if (field == "title") {
        if (value.length > 20) {
          error("Max 20 characters are allowed");
        }
        const updatedSections = allValues.sections.map(section =>
          section.id === id
            ? {
                ...section,
                [field]: value.length > 20 ? value.slice(0, 20) : value
              }
            : section
        );

        const temp = {
          ...allValues,
          sections: updatedSections
        };
        setValues(temp);
        setAllValues(temp);
        onTextChange(props?.id, temp);
      } else if (field == "description") {
        if (value.length > 72) {
          error("Max 72 characters are allowed");
        }
        const updatedSections = allValues.sections.map(section =>
          section.id === id
            ? {
                ...section,
                [field]: value.length > 72 ? value.slice(0, 72) : value
              }
            : section
        );

        const temp = {
          ...allValues,
          sections: updatedSections
        };
        setValues(temp);
        setAllValues(temp);
        onTextChange(props?.id, temp);
      }

      // setAllValues((prevState) => ({
      //   ...prevState,
      //   sections: updatedSections,
      // }));
    }
  };

  const removeTextField = index => () => {
    const updatedData = allValues.sections.filter((_, idx) => idx !== index);
    // setAllValues((prevState) => ({
    //   ...prevState,
    //   sections: updatedData,
    // }));
    const temp = {
      ...allValues,
      sections: updatedData
    };
    setValues(temp);
    setAllValues(temp);
    onTextChange(props?.id, temp);
  };

  const handleDeleteParentNode = () => {
    // console.log(parentId, "inside component", props?.id);
    deleteParentNode(props?.id);
  };

  const setAdditionalFieldsData = (id, value, index, flag) => {
    const updatedSections = allValues.sections.map((section, idx) => {
      if (idx === index) {
        if (flag === "title") {
          if (value.length > 24) {
            error("Maximum character limit is 24");
            return {
              ...section,
              title: value.slice(0, 24)
            };
          } else {
            return {
              ...section,
              title: value
            };
          }
        } else if (flag === "description") {
          if (value.length > 72) {
            error("Maximum character limit is 72");
            return {
              ...section,
              description: value.slice(0, 72)
            };
          } else {
            return {
              ...section,
              description: value
            };
          }
        }
      }
      return section;
    });

    // setAllValues((prevState) => ({
    //   ...prevState,
    //   sections: updatedSections,
    // }));
    const temp = {
      ...allValues,
      sections: updatedSections
    };
    setValues(temp);
    setAllValues(temp);
    onTextChange(props?.id, temp);
  };

  const Error = props => {
    const field1 = props.field;
    if (errors[field1] || touched[field1] || submitCount > 0) {
      // console.log("inside if any from formik");
      return (
        <>
          {/* <p className={`${props.class ? props.class : "error-msg w-100"}`}> */}
          {/* </p> */}
          <aside className="error-message-row">
            <span className={props.class ? props.class : "error-msg"}>
              {errors[field1]}
            </span>
          </aside>
        </>
      );
    } else {
      return <span />;
    }
  };

  // Modified Error component to handle errors for buttons
  const ButtonError = ({ index }) => {
    // const buttonField = `buttons[${index}].value`;

    // console.log("if any from formik errors:", errors);
    // console.log("if any from formik touched:", touched);

    if (
      (errors.sections && errors.sections[index]) || // Check if the button field is touched
      submitCount > 0
    ) {
      // console.log(" if any from formik Error condition satisfied.");
      return (
        <aside className="error-message-row">
          <span className={props.class ? props.class : "error-msg"}>
            {errors.sections[index].title}
          </span>
        </aside>
      );
    } else {
      // console.log("if any from formik No error.");
      return <span />;
    }
  };

  return (
    <>
      <div
        onClick={() => {
          setSelectedNode(props?.id);
        }}
        className="text-updater-node flow-comn-box"
      >
        <div className="flow-title-box">
          <aside>Message</aside>
          <Handle type="target" className="flow-trigger flow-trigger-fill" />
          {props?.type !== "Template" ? (
            <>
              <div className="header-btn-row">
                <Tooltip title="Preview">
                  <div
                    onClick={() => {
                      setSelectedNode(props?.id);
                      setPreviewState(true);
                    }}
                    className="preview-eye-button"
                  >
                    <i className="fas fa-eye"></i>
                  </div>
                </Tooltip>

                <Tooltip title="Clone">
                  <div
                    onClick={() => {
                      duplicateNode(props?.id);
                    }}
                    className="preview-clone-button"
                  >
                    <i className="fas fa-clone"></i>{" "}
                  </div>
                </Tooltip>
                <Tooltip title="Delete">
                  <div
                    onClick={handleDeleteParentNode}
                    className="preview-clone-button"
                  >
                    <i className="fas fa-trash"></i>{" "}
                  </div>
                </Tooltip>
              </div>
            </>
          ) : null}
        </div>
        <div className="flow-inner-row flow-textarea-row">
          {["header", "body", "footer", "title"].map((field, index) => (
            <>
              <aside>
                <TextField
                  size="large"
                  id={field}
                  onBlur={handleBlur}
                  multiline={true}
                  minRows={field == "body" ? 4 : field == "title" ? 2 : 1}
                  placeholder={
                    field == "body" || field == "title"
                      ? `${field}`
                      : `${field} (optional)`
                  }
                  value={allValues[field]}
                  onChange={e =>
                    handleChange(field, "value", e.target.value, true)
                  }
                />
                <Error field={field} />
              </aside>
            </>
          ))}
          {allValues.sections.map((field, index) => (
            <>
              <div
                className="text-btn-add-row"
                style={{ position: "relative" }}
              >
                <div className="position-relative">
                  <TextField
                    label={`Title ${index + 1}`}
                    value={field.title}
                    multiline={false}
                    minRows={1}
                    id="sections"
                    onBlur={handleBlur}
                    onChange={e =>
                      setAdditionalFieldsData(
                        field.id,
                        e.target.value,
                        index,
                        "title"
                      )
                    }
                  />
                  <ButtonError index={index} />
                </div>
                <TextField
                  className="mt-1"
                  label={`Description ${index + 1}`}
                  value={field.description}
                  multiline
                  minRows={2}
                  onChange={e =>
                    setAdditionalFieldsData(
                      field.id,
                      e.target.value,
                      index,
                      "description"
                    )
                  }
                />
                <p>
                  <Handle
                    id={`${props?.id}_${field.id}`}
                    type="source"
                    className="flow-trigger"
                  ></Handle>
                </p>
                <p
                  className="flow-comn-close-btn"
                  onClick={removeTextField(index)}
                  style={{ cursor: "pointer" }}
                >
                  <i className="fas fa-times-circle"></i>
                </p>
              </div>
            </>
          ))}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Button
              className="flow-comn-btn"
              variant="contained"
              onClick={addTextField}
            >
              <i className="fas fa-plus mr-2"></i> Add List Section
            </Button>
          </div>
          {/* Displaying all values of text fields */}
          {/* <pre>{JSON.stringify(allValues, null, 2)}</pre> */}
        </div>
      </div>
    </>
  );
};

export default Enhancer(TestingListNode);
